<template>
  <div class="footer">
    <div class="footer-container">
      <section class="all-links">
        <div class="page-container">
          <div class="inner-block">
            <div class="company">
              <div class="company-content">
                <h2>Roanuz Cricket API</h2>
                <p>A product of <a target="_blank" href="//www.roanuz.com/?ref=rs-footer" rel="noopener">Roanuz</a></p>
              </div>
              <div class="address">
                <h3>Chennai, India</h3>
                <p>4th Floor, 284/1B, OMR,<br>
                  Perungudi,<br>
                  Chennai - 600096.
                </p>
              </div>
              <div class="address">
                <h3>Reykjavík, Iceland</h3>
                <p> Austurstræti 17, 4. Hæð<br>
                  101 Reykjavík<br>
                  Iceland.
                </p>
              </div>
            </div>
            <div class="links">
              <b>Everything</b>
              <div class="links-section-wrapper">
                <div
                :key="linkslist.key"
                v-for="linkslist in footerLinks"
                class="links-section"
                >
                <ul>
                  <template v-if="linkslist && linkslist.linkslist && linkslist.linkslist.links">
                    <li
                      v-for="link in linkslist.linkslist.links"
                      :key="link.key"
                    >
                      <rz-link-item :link="link" />
                    </li>
                  </template>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="toc">
        <div class="page-container">
          <div class="inner-block">
            <div class="toc-wrapper">
              <div class="links" v-if="tocLinks.linkslist">
                <ul>
                  <li
                    v-for="link in tocLinks.linkslist.links"
                    :key="link.key"
                  >
                    <rz-link-item :link="link" />
                  </li>
                </ul>
              </div>
              <div class="social">
                <div class="icon">
                  <a href="//twitter.com/CricketApiRz" target="_blank" rel="noopener">
                    <img src="@/assets/social-icon-twitter.svg" alt="Twitter Icon">
                  </a>
                </div>
                <div class="icon">
                  <a href="//www.facebook.com/CricketApiRZ" target="_blank" rel="noopener">
                    <img src="@/assets/social-icon-fb.svg" alt="Facebook Icon">
                  </a>
                </div>
                <div class="icon">
                  <a href="//www.instagram.com/cricketapi/" target="_blank" rel="noopener">
                    <img src="@/assets/social-icon-instagram.svg" alt="Instagram Icon">
                  </a>
                </div>
                <div class="icon">
                  <a href="//www.linkedin.com/company/roanuz/" target="_blank" rel="noopener">
                    <img src="@/assets/social-icon-linkedin.svg" alt="Linkedin Icon">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .footer {
    --footer-links-title-pad-bottom: #{size(4)};
    --footer-page-pad-bottom: #{size(45)};

    @media screen and (min-width: $breakpoint-md) {
      --footer-links-title-pad-bottom: #{size(8)};
      --footer-page-pad-bottom: #{size(45)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --footer-links-title-pad-bottom: #{size(12)};
      --footer-page-pad-bottom: #{size(65)};
    }
    &.docsResource {
      .inner-block {
        margin-left: 0;
      }
    }

    padding:
      0
      0
      var(--footer-page-pad-bottom)
      0;
    font-size: size(14);

    .all-links {
      padding-top: size(24);

      .company {
        h2 {
          font-weight: bold;
          font-size: size(18);
          color: var(--regular-text);
        }

        p, span.p {
          font-size: size(12);
          // padding-top: size(8);
        }

        a {
          text-decoration: underline;
        }

        .address {
          margin-top: size(28);
          &:last-child {
            margin-top: size(20);
          }
          h3, p {
            font-size: size(14);
            line-height: size(17);
          }
          h3 {
            font-weight: bold;
            margin-bottom: size(5);
          }
        }
      }

      .links {
        padding: size(30) 0 size(24) 0;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(30) 0 size(24) size(48 - 12);
        }

        b{
          padding-bottom: var(--footer-links-title-pad-bottom);
          display: block;
        }

        .links-section {
          margin-right: size(10);
          margin-top: size(16);
          order: 2;
          &:last-child {
            order: unset;
            margin-left: 0;
            margin-top: size(0);
          }
        }

        .links-section-wrapper {
          display: flex;
          flex-direction: column;
          @media screen and (min-width: $breakpoint-md) {
            flex-direction: initial;
          }
        }

        ul {
          @media screen and (min-width: $breakpoint-lg) {
            margin: size(4) 0;
          }

          li {
            padding: size(8) 0;
            @media screen and (min-width: $breakpoint-lg) {
              padding: size(6) 0;
            }
          }
        }
      }

    }
    .toc {
      border-top: 1px solid var(--gray-line);
      padding-top: size(10);
      .toc-wrapper {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: normal;
        width: 100%;
        .links {
          .link-item {
            display: inline-block;
          }

          li {
            display: block;
            padding: size(10) 0;
            text-transform: uppercase;
            letter-spacing: 0.09em;
            font-size: size(12);
            @media screen and (min-width: $breakpoint-lg) {
              padding: size(10) size(48 - 12);
            }
          }
        }

        .social {
          margin-top: size(16);
          padding-left: 0;
          @media screen and (min-width: $breakpoint-lg) {
            padding-left: size(48 - 12);
          }

          .icon {
            display: inline-block;
            margin-left: size(20);
            width: size(18);

            &:first-child {
              margin-left: 0;
            }

            &:hover {
              @include jump-up-animation;
            }
          }
        }
      }
    }

    .inner-block {
      margin-left: var(--senior-menu-width);
      padding-left: var(--docs-page-pad-left);
    }

    @media screen and (min-width: $breakpoint-md) {
      font-size: size(18);

      .all-links {
        // padding-left: calc( var(--senior-menu-width) + var(--docs-page-pad-left));
        padding-top: size(36);
        .company {
          h2 {
            font-size: size(28);
            line-height: size(44);
          }

          p, span.p {
            font-size: size(16);
          }

          .address {
            h3, p {
              font-size: size(16);
              line-height: size(19);
            }
            h3 {
              margin-bottom: size(10);
            }
          }
        }

        .links {
          padding: size(40) 0 size(48) size(20);

          .links-section {
            margin-right: size(100);
            margin-top: 0;
          }

          ul {
            margin-top: size(4);
            margin-bottom: 0;

            li {
              padding: size(4) 0;
            }
          }
        }
      }

      .toc {
        padding-top: size(22);
        .toc-wrapper {
          .links {
            li {
              display: inline-block;
              padding: 0 size(16);
              &:first-child {
                padding-left: 0;
              }
            }
          }

          .social {
            padding-left: 0;
            margin-top: size(28);
          }
        }
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      .inner-block {
        margin-left: var(--senior-menu-width);
        padding-left: var(--docs-page-pad-left);
        flex-direction: row;
        justify-content: space-between;
        display: flex;
      }
      .all-links {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // padding-top: 0;

        .links {
          padding: size(56) size(100) size(56) 0;

          .links-section {
            margin-left: size(130);
            margin-right: 0;
          }
        }

        // .company {
        //   p {
        //     padding-top: size(7);
        //   }
        // }
      }

      .toc{
        .toc-wrapper {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-right: size(60);

          .links {
            li {
              display: inline-block;
              padding: 0 size(24);
            }
          }
          .social {
            margin-top: 0;
          }
        }
      }
    }
    &:not(.docsHome) .footer-container {
      border-top: 1px solid var(--gray-line);
    }
  }
</style>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      footerLinks: (state) => state.link.cricketFooter,
      tocLinks: (state) => state.link.cricketFooterTOC,
    }),
  },

  data() {
    return {
      observer: null,
    };
  },

  mounted() {
    this.toggleSeniorMenu();
  },

  methods: {
    toggleSeniorMenu() {
      if (this.$route.name !== 'docsResource') {
        window.addEventListener('scroll', () => {
          this.observeVisiblity();
        });
      }
    },
    onElementVisible(value) {
      if (value && ((document.body.clientHeight - this.$el.clientHeight) > window.innerHeight)) {
        this.$store.commit('link/updateSeniorMenuState', { hide: true });
      } else {
        this.$store.commit('link/updateSeniorMenuState', { hide: false });
      }
    },
    observeVisiblity() {
      if (!IntersectionObserver) {
        // Browser not supported.
        console.log('Browser not supported for IntersectionObserver');
        this.onElementVisible(false);
        return;
      }
      this.observer = new IntersectionObserver((entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          this.observer.disconnect();
          this.onElementVisible(firstEntry.isIntersecting);
        } else {
          this.onElementVisible(false);
        }
      });
      this.$nextTick(() => {
        if (this.observer) {
          this.observer.observe(this.$el);
        }
      });
    },
  },
};
</script>
