<template>
  <SmartMorphIcon
    :state.sync="localState"
    @click="s => $emit('click', s)"
    @toggle="s => $emit('toggle', s)"
    @stateChange="s => $emit('stateChange', s)"
    frameIdPrefix="BurgerMenuIconStep-" :rootClass="rootClass">
      <g id="BurgerMenuIconStep-1">
        <g id="Group 8">
          <rect id="Rectangle 337" x="20" y="55" width="260" height="30" fill="black" transform="rotate(0)"/>
          <rect id="Rectangle 338" x="20" y="135" width="260" height="30" fill="black" transform="rotate(0)" />
          <rect id="Rectangle 339" x="20" y="215" width="260" height="30" fill="black" transform="rotate(0)" />
        </g>
      </g>
      <g id="BurgerMenuIconStep-2">
        <g id="Group 8">
          <rect id="Rectangle 337" x="20" y="135" width="260" height="30" fill="black" transform="rotate(0)" />
          <rect id="Rectangle 338" x="20" y="135" width="260" height="30" fill="black" transform="rotate(0)" />
          <rect id="Rectangle 339" x="20" y="135" width="260" height="30" fill="black" transform="rotate(0)" />
        </g>
      </g>
      <g id="BurgerMenuIconStep-3">
        <g id="Group 8">
          <rect
            id="Rectangle 337"
            x="68.6827"
            y="47.4695"
            width="260"
            height="30"
            transform="rotate(45 68.6827 47.4695)"
            fill="black"
          />
          <rect
            id="Rectangle 339"
            x="47.4695"
            y="231.318"
            width="260"
            height="30"
            transform="rotate(-45 47.4695 231.318)"
            fill="black"
          />
        </g>
      </g>
      <g id="BurgerMenuIconStep-4">
        <g id="Group 8">
          <rect
            id="Rectangle 337"
            x="102.483"
            y="90.0001"
            width="153"
            height="17.6538"
            transform="rotate(45 102.483 90.0001)"
            fill="black"
          />
          <rect
            id="Rectangle 339"
            x="90"
            y="198.187"
            width="153"
            height="17.6538"
            transform="rotate(-45 90 198.187)"
            fill="black"
          />
        </g>
        <circle id="Ellipse 5" cx="150" cy="150" r="120" stroke="black" stroke-width="20" />
      </g>
  </SmartMorphIcon>
</template>
<script>
import SmartMorphIcon from '@/components/InteractiveIcons/SmartMorphIcon.vue';

export default {
  name: 'DropdownIcon',
  components: { SmartMorphIcon },
  props: {
    rootClass: {
      type: String,
      default: '',
    },
    state: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      localState: this.state,
    };
  },
  watch: {
    state(newVal) {
      if (this.localState !== newVal) {
        this.localState = newVal;
      }
    },
    localState(newVal) {
      this.$emit('update:state', newVal);
    },
  },
};
</script>
