<template>
  <div v-if="pageSection">
    <section class=" hero data-card">
      <div class="section-desc-area">
        <div class="hero-p">
          <h2 v-if="pageSection.name">{{pageSection.name}}</h2>
          <rz-markdown v-if="pageSection.desc" :text="pageSection.desc"></rz-markdown>
          <div class="line-path-map">
            <img class="country-line-path show-on-desktop" src="@/assets/path-4.svg" />
            <img class="association-line-path show-on-desktop" src="@/assets/path-1.svg" />
            <img class="competition-line-path show-on-desktop" src="@/assets/path-2.svg" />
            <img class="tournament-line-path show-on-desktop" src="@/assets/path-3.svg" />
            <img class="country-line-path show-on-mobile" src="@/assets/path-5.svg" />
            <img class="association-line-path show-on-mobile" src="@/assets/path-6.svg" />
            <img class="competition-line-path show-on-mobile" src="@/assets/path-7.svg" />
            <img class="tournament-line-path show-on-mobile" src="@/assets/path-8.svg" />
            <span></span>
          </div>
        </div>
        <div class="image-area" v-if="pageSection.img">
          <rz-image-item :image="pageSection.img" />
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
  .data-structure, .data-structure-docs {
    --data-structure-title-font-size: #{size(18)};
    --data-structure-desc-font-size: #{size(14)};
    --data-structure-title-height: #{size(24)};
    --data-structure-desc-height: #{size(17)};

    --title-pad-top: #{size(8)};
    --title-pad-side: #{size(12)};
    --section-para-max-width: #{size(550)};

    --data-section-pad-top: #{size(28)};
    --data-section-pad-left: #{size(28)};
    --data-section-pad-right: #{size(28)};
    --data-section-pad-bottom: #{size(42)};

    --data-bg-color-1: #F6F3F1;
    --data-bg-color-2: #7F91B2;
    --data-bg-color-3: #F4BB5E;
    --data-bg-color-4: #F2C4BB;
    --title-pad-bg: #35353D;

    @media screen and (min-width: $breakpoint-md) {
      --data-structure-title-font-size: #{size(28)};
      --data-structure-desc-font-size: #{size(16)};
      --data-structure-title-height: #{size(37)};
      --data-structure-desc-height: #{size(19)};

      --data-section-pad-top: #{size(36)};
      --data-section-pad-left: #{size(24)};
      --data-section-pad-right: #{size(24)};
      --data-section-pad-bottom: #{size(60)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --data-structure-title-font-size: #{size(38)};
      --data-structure-desc-font-size: #{size(18)};
      --data-structure-title-height: #{size(51)};
      --data-structure-desc-height: #{size(24)};

      --title-pad-top: #{size(12)};
      --title-pad-side: #{size(16)};

      --data-section-pad-top: #{size(40)};
      --data-section-pad-left: #{size(132)};
      --data-section-pad-right: #{size(68)};
      --data-section-pad-bottom: #{size(120)};
    }

    .show-on-desktop {
      display: none;
    }
    .show-on-mobile {
      display: inline-block;
    }
    @media screen and (min-width: $breakpoint-md) {
      .show-on-desktop {
        display: inline-block;
      }
      .show-on-mobile {
        display: none;
      }
    }
    &.data-structure-0, &.data-structure-4,
    &.data-structure-docs-0, &.data-structure-docs-4  {
      background-color: var(--data-bg-color-1);
    }
    &.data-structure-1, &.data-structure-5,
    &.data-structure-docs-1, &.data-structure-docs-5 {
      background-color: var(--data-bg-color-2);
    }
    &.data-structure-2, &.data-structure-6,
    &.data-structure-docs-2, &.data-structure-docs-6 {
      background-color: var(--data-bg-color-3);
    }
    &.data-structure-3 ,
    &.data-structure-docs-3{
      background-color: var(--data-bg-color-4);
    }
    &.data-structure-4, &.data-structure-5, &.data-structure-6,
    &.data-structure-docs-4, &.data-structure-docs-5, &.data-structure-docs-6 {
      .line-path-map {
        display: none;
      }
    }
      &.data-structure-1, &.data-structure-3,
      &.data-structure-docs-1, &.data-structure-docs-3 {
      section {
        &.data-card {
          @media screen and (min-width: $breakpoint-md) {
            padding-left: 0;
            padding-right: var(--data-section-pad-right);
          }
          .section-desc-area {
            @media screen and (min-width: $breakpoint-md) {
              flex-direction: row-reverse;
              .hero-p {
                padding-left: size(40);
                padding-right: 0;
              }
            }
            @media screen and (min-width: $breakpoint-lg) {
              .hero-p {
                padding-left: size(88);
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    &.data-structure-0,
    &.data-structure-docs-0 {
      .section-desc-area {
        .line-path-map {
          .country-line-path {
            right: size(64);
            @media screen and (min-width: $breakpoint-md) {
              bottom: 0;
              width: size(297);
              right: 35%;
              transform: translateY(size(12));
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(478);
              left: 16%;
            }
          }
          .competition-line-path, .association-line-path, .tournament-line-path {
            display: none;
          }
          span {
            background: var(--data-bg-color-2);
            right: size(46);
            @media screen and (min-width: $breakpoint-md) {
              right: 30%;
            }
            @media screen and (min-width: $breakpoint-lg) {
              left: size(618);
            }
          }
        }
      }
    }
    &.data-structure-1,
    &.data-structure-docs-1 {
      .section-desc-area {
        .hero-p {
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: size(15);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-bottom: size(0);
          }
        }
        .line-path-map {
          .association-line-path {
            left: size(66);
            @media screen and (min-width: $breakpoint-md) {
              left: size(72);
              width: size(293);
              transform: translateY(size(12));
              bottom: 0;
            }
            @media screen and (min-width: $breakpoint-lg) {
              left: size(234);
              width: size(482);
              transform: translateY(size(15));
            }
          }
          .country-line-path, .tournament-line-path, .competition-line-path {
            display: none;
          }
          span {
            background: var(--data-bg-color-3);
            left: size(54);
            @media screen and (min-width: $breakpoint-lg) {
              left: size(194);
            }
          }
        }
      }
    }
    &.data-structure-2,
    &.data-structure-docs-2 {
      .section-desc-area {
        .line-path-map {
          .competition-line-path {
            right: size(115);
            @media screen and (min-width: $breakpoint-md) {
              width: size(250);
              right: 45%;
              bottom: 0;
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(392);
              left: size(274);
            }
          }
          .association-line-path, .tournament-line-path, .country-line-path {
            display: none;
          }
          span {
            background: var(--data-bg-color-4);
            right: size(116);
            @media screen and (min-width: $breakpoint-md) {
              right: 40%;
            }
            @media screen and (min-width: $breakpoint-lg) {
              left: size(630);
            }
          }
        }
      }
    }
    &.data-structure-3,
    &.data-structure-docs-3 {
      .section-desc-area {
        .line-path-map {
          .tournament-line-path {
            right: size(60);
            @media screen and (min-width: $breakpoint-md) {
              width: size(200);
              right: size(90);
              bottom: 0;
              transform: translateY(size(5));
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(380);
              right: size(220);
              transform: translateY(size(10));
            }
          }
          .association-line-path, .country-line-path, .competition-line-path {
            display: none;
          }
          span {
            background: var(--data-bg-color-1);
            right: size(48);
            @media screen and (min-width: $breakpoint-md) {
              right: size(66);
            }
            @media screen and (min-width: $breakpoint-lg) {
              right: size(186);
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .data-structure {
    position: relative;
    &.data-structure-1, &.data-structure-5 ,
    &.data-structure-docs-1, &.data-structure-docs-5{
      section {
        &.data-card {
          .section-desc-area {
            .hero-p {
              .markdown {
                .p {
                  color: var(--master-text);
                }
              }
            }
          }
        }
      }
    }
    &.data-structure-5, &.data-structure-6 ,
    &.data-structure-docs-5, &.data-structure-docs-6{
      width: 100%;
      margin-bottom: size(60);
      @media screen and (min-width: $breakpoint-md) {
        margin-bottom: size(80);
        flex: 0 0 50%;
      }
      @media screen and (min-width: $breakpoint-lg) {
        margin-bottom: size(100);
      }
      section {
        &.data-card {
          .section-desc-area {
            .hero-p {
              span {
                &.p {
                  padding-bottom: 0;
                }
              }
            }
          }
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: size(38);
            .section-desc-area {
              .hero-p {
                span {
                  &.p {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding: size(60);
            .section-desc-area {
              .hero-p {
                padding-left: 0;
                span {
                  &.p {
                    padding-bottom: 0;
                  }
                }
                h2 {
                  margin-top: 0;
                }
              }
            }
          }
          .image-area {
            @media screen and (min-width: $breakpoint-md) {
              position: absolute;
              width: size(40);
              min-height: size(38);
              top: size(36);
              left: size(152);
              padding-bottom: 0;
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(58);
              min-height: size(56);
              top: size(60);
              left: size(230);
            }
          }
        }
      }
    }
    &.data-structure-5,
    &.data-structure-docs-5 {
      margin-bottom: 0;
      @media screen and (min-width: $breakpoint-md) {
        margin-bottom: size(80);
      }
      @media screen and (min-width: $breakpoint-lg) {
        margin-bottom: size(100);
      }
      section {
        &.data-card {
          .image-area {
            @media screen and (min-width: $breakpoint-md) {
              position: absolute;
              width: size(58);
              min-height: size(55);
              top: size(36);
              left: size(150);
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(90);
              min-height: size(85);
              left: size(220);
              top: size(52);
            }
          }
        }
      }
    }
    section {
      &.data-card {
        padding: var(--data-section-pad-top)
          var(--data-section-pad-right)
          var(--data-section-pad-bottom)
          var(--data-section-pad-left);
        @media screen and (min-width: $breakpoint-md) {
          padding-right: 0;
          padding-bottom: 0;
        }
        .section-desc-area {
          display: flex;
          justify-content: space-between;
          @media screen and (min-width: $breakpoint-md) {
            align-items: center;
          }
          .hero-p {
            h2 {
              display: inline-block;
              font-family: var(--hero-font);
              font-size: var(--data-structure-title-font-size);
              line-height: var(--data-structure-title-height);
              color: var(--master-text);
              padding: var(--title-pad-top) var(--title-pad-side);
              background-color: var(--title-pad-bg);
              border-radius: size(3);
              margin-bottom: size(16);
              @media screen and (min-width: $breakpoint-lg) {
                margin-bottom: size(24);
                margin-top: size(20);
              }
            }
            .markdown {
              .p {
                font-size: var(--data-structure-desc-font-size);
                line-height: var(--data-structure-desc-height);
                font-family: var(--regular-font);
                margin-right: 0;
                max-width: var(--section-para-max-width);
                color: var(--secondary-text);
                padding-right: 0;
                @media screen and (min-width: $breakpoint-md) {
                  flex: 0 0 45%;
                  padding-bottom: var(--data-section-pad-bottom);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  flex: 0 0 60%;
                }
              }
            }
          }
        }
      }
    }
  }
  .data-card {
    .section-desc-area {
      .hero-p {
        text-align: center;
        @media screen and (min-width: $breakpoint-md) {
          text-align: left;
          padding-right: size(24);
        }
      }
      .line-path-map {
        img {
          position: absolute;
          z-index: 3;
        }
        span {
          display: inline-block;
          width: size(60);
          height: size(26);
          position: absolute;
          bottom: 0;
          @media screen and (min-width: $breakpoint-md) {
            width: size(80);
            height: size(36);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(108);
            height: size(44);
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
    .image-area {
      display: none;
      @media screen and (min-width: $breakpoint-md) {
        width: size(266);
        min-height: size(172);
        display: flex;
        flex: 1 0 auto;
        padding-bottom: size(70);
        .image-item {
          img, picture {
            max-height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: size(530);
        min-height: size(288);
        padding-bottom: size(60);
      }
    }
  }
</style>
<script>

export default {
  props: {
    pageSection: Object,
    index: Number,
  },
};
</script>
