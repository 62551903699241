<template>
  <div class="page-docs-api" v-if="page">
    <div class="loader" v-if="!pageState.status.isLoaded">
      <LoadingPlaceholder :state="pageState" :placeholderStyleNumber="placeholderStyleNumber"/>
    </div>
    <div class="content" v-else>
      <GotoTop />
      <JuniorMenu :links="page.toc"/>
      <div class="hero">
        <h1 class="hero-title">{{ page.title }}</h1>
        <div
          @click="onSeniorMenuClick"
          class="show-on-mobile">
          <div class="hero-title-block">
            <h1>{{ page.title }}</h1>
            <div class="icon-box">
              <DropdownIcon
                :rootClass="'color-secondary'"
                :state="seniorMenuState.animationState"
                @updateState="(state) => {updateLinkAnimationState(state)}"
                @iconClick="(state) => {onSubMenuIconClick(state)}"
              />
            </div>
          </div>
        </div>
        <div class="desc-area">
          <div class="hero-p">
            <rz-markdown :text="page.brief"></rz-markdown>
          </div>
          <div class="image-area" v-if="page.img">
            <rz-image-item :image="page.img" />
          </div>
        </div>
      </div>
      <div
       v-if="page.hasMoreDeliveryMethods"
       class="page-tabs">
        <ul>
          <li
          v-for="(dm, i) in page.supported_delivery_methods"
          :key="i"
          :class="{active: dm.method.is(page.delivery_method)}"
          >
            <rz-link-item :link="dm.link" />
          </li>
        </ul>
      </div>

      <section class="req-struct first-section bg-2 hover-element" id="goto-req-struct">
        <h2>Request structure</h2>
        <button class="url-btn" @click="copyUrl('goto-req-struct')"><img src="@/assets/link.svg" /></button>
        <p v-if="page.request_desc"><rz-markdown :text="page.request_desc" /></p>
        <div class="box">
          <div class="box-content">
            <RequestStructureSection :schema="schema" :state="schemaState"/>
          </div>
        </div>
      </section>

      <section
        v-for="(sampleCode, i) in page.sample_codes"
        :key="i"
        class="sample-code hover-element"
        :id="`goto-sample-code-${i}`" >
        <h2 v-if="sampleCode.name">{{sampleCode.name}}</h2>
        <button  class="url-btn" @click="copyUrl(`goto-sample-code-${i}`)" v-if="sampleCode.name">
          <img src="@/assets/link.svg" /></button>
        <p v-if="sampleCode.desc"><rz-markdown :text="sampleCode.desc" /></p>
        <div class="box" v-if="sampleCode.code_groups.length">
          <div class="box-content bg-white">
            <SampleCodeSection :codeGroups="sampleCode.code_groups"/>
          </div>
        </div>
      </section>

      <section
        id="goto-scenarios"
        v-if="page.scenarios_title"
        class="scenarios bg-2 hover-element" >
        <h2>{{page.scenarios_title}}</h2>
        <button  class="url-btn" @click="copyUrl('goto-scenarios')"><img src="@/assets/link.svg" /></button>
        <p v-if="page.scenarios_desc"><rz-markdown :text="page.scenarios_desc" /></p>
        <div class="box">
          <div class="box-content">
            <DataDescSection
              @showResponse="showResponse"
             :itemKey="'scenarios'"
             :page="page"
             :responseState="pageResponsesState"
             :state="pageDetailState"/>
          </div>
        </div>
      </section>

      <section
        id="goto-explores"
        v-if="page.explores_title"
        class="explores hover-element" >
        <h2>{{page.explores_title}}</h2>
        <button  class="url-btn" @click="copyUrl('goto-explores')"><img src="@/assets/link.svg" /></button>
        <p v-if="page.explores_desc"><rz-markdown :text="page.explores_desc" /></p>
        <div class="box">
          <div class="box-content">
            <DataDescSection
              @showResponse="showResponse"
              :itemKey="'explores'"
              :page="page"
              :responseState="pageResponsesState"
              :state="pageDetailState"/>
          </div>
        </div>
      </section>

      <section v-if="page.title === 'Fantasy Match Points API'" id="fantasy-points-system">
        <h2>Roanuz Fantasy Point System</h2>
        <p>Roanuz Cricket API provides the AI powered Cricket Fantasy Point system that is
           calculated based on the players recent performance stats.
          The Fantasy Points can  be customised based on your requirements.</p>
        <p><a href="/v5/fantasy-points-system">Cricket Fantasy Point System</a></p>
      </section>

      <section
        id="goto-http-status"
        class="http-status bg-2 hover-element" >
        <h2>HTTP Status</h2>
        <button  class="url-btn" @click="copyUrl('goto-http-status')"><img src="@/assets/link.svg" /></button>
        <p v-if="page.http_desc"><rz-markdown :text="page.http_desc" /></p>
        <div class="box">
          <div class="box-content">
            <HTTPStatusSection
              @showResponse="showResponse"
              :page="page"
              :responseState="pageResponsesState"
              :state="pageDetailState"/>
          </div>
        </div>
      </section>
      <section
        v-if="!ignoreSlugs.includes($route.params.page)"
        id="goto-cache"
        class="page-cache-section hover-element" >
        <h2>Cache</h2>
        <button class="url-btn" @click="copyUrl('goto-cache')"><img src="@/assets/link.svg" /></button>
        <p>A cache object accompanies every API response.
          It comes with a set of recommended values to help
          you properly cache the data and handle the cache internally.</p>
        <div class="box">
          <div class="box-content">
            <CacheSection />
          </div>
        </div>
      </section>

      <section
        id="goto-downloads"
        v-if="page.downloads_title"
        class="downloads  bg-2 hover-element" >
        <h2>{{page.downloads_title}}</h2>
        <button class="url-btn" @click="copyUrl('goto-downloads')"><img src="@/assets/link.svg" /></button>
        <p v-if="page.downloads_desc"><rz-markdown :text="page.downloads_desc" /></p>
        <div class="box">
          <div class="box-content">
            <DataDescSection
              @showResponse="showResponse"
              :itemKey="'downloads'"
              :page="page"
              :responseState="pageResponsesState"
              :state="pageDetailState"/>
          </div>
        </div>
      </section>

      <section
        id="goto-res-schema"
        v-if="page.schema_desc"
        class="page-schema-section inner-margin hover-element">
        <h2>Response Schema</h2>
        <button class="url-btn" @click="copyUrl('goto-res-schema')"><img src="@/assets/link.svg" /></button>
        <p v-if="page.schema_desc"><rz-markdown :text="page.schema_desc" /></p>
        <div class="box">
          <div class="box-content">
            <ResponseSchemaSection
              @showSchemaDefinition="showSchemaDefinition"
             :page="page"
             :schema="schema" :state="schemaState"/>
          </div>
        </div>
      </section>

      <section class="pull-left page-support-section">
        <SupportSection />
      </section>

      <section
        class="page-related-api">
        <RelatedAPIsSection :page="page" />
      </section>

      <div v-if="isCopied" class="toaster-msg">
        <span>Link Copied</span>
      </div>

      <SlideInPopup
        v-if="activePreviewSample || activePreviewObject"
        :title="activePreviewTitle"
        :desc="activePreviewDesc"
        :downloadUrl="activePreviewDownloadUrl"
        :downloadFilename="activePreviewDownloadFilename"
        :keepParentFocus="false"
        :keepParentScroll="false"
        :sidebarLinks="page.detail['downloads'] ? page.detail['downloads'] : null"
        :pageResponse="page"
        :activeLink="activePreviewSample ? activePreviewSample.response_key : null"
        :showSidebar="activePreviewSample && Object.keys(activePreviewSample).length > 0"
        @shouldClose="slideInShouldClose()"
        @downloadClick="slideInAsksDownload()"
        @showResponse="showResponse"
        >
        <template v-if="activePreviewSample">
          <div class="loader" v-if="!activePreviewSampleState.status.isLoaded">
            <rz-request-state :state="activePreviewSampleState" />
          </div>
          <div v-else>
            <json-preview :src="activePreviewObject" />
          </div>
        </template>
        <template v-else>
          <DataclassDisplay
              v-if="activePreviewObject.typ === 'dataclass'"
              @showSchemaDefinition="showSchemaDefinition"
              :dataclass="activePreviewObject"
              :defs="schema.defs" />
          <EnumDisplay
              v-else-if="activePreviewObject.typ === 'enum'"
              :enumDef="activePreviewObject"
              :defs="schema.defs" />
        </template>
      </SlideInPopup>
    </div>
  </div>
</template>
<script>
import '@/assets/styles/views/docs_api.scss';

import { RequestState, RequestStateStatus } from '@/api/core';
import { APISchema } from '@/store/modules/schema';
import { mapState } from 'vuex';
import { SmartMorphAnimationState } from '@/core/view/smartMorph';

import RequestStructureSection from '@/components/API/RequestStructureSection.vue';
import DataDescSection from '@/components/API/DataDescSection.vue';
import SampleCodeSection from '@/components/API/SampleCodeSection.vue';
import HTTPStatusSection from '@/components/API/HTTPStatusSection.vue';
import CacheSection from '@/components/API/CacheSection.vue';
import ResponseSchemaSection from '@/components/API/ResponseSchemaSection.vue';
import SupportSection from '@/components/SupportSection.vue';
import RelatedAPIsSection from '@/components/API/RelatedAPIsSection.vue';
import SlideInPopup from '@/components/API/SlideInPopup.vue';
import JuniorMenu from '@/components/JuniorMenu.vue';
import GotoTop from '@/components/GotoTop.vue';
import JSONPreview from '@/components/CodeDisplay/JSONPreview.vue';
import DataclassDisplay from '@/components/CodeDisplay/DataclassDisplay.vue';
import EnumDisplay from '@/components/CodeDisplay/EnumDisplay.vue';
import DropdownIcon from '@/components/InteractiveIcons/DropdownIcon.vue';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';

export default {
  components: {
    RequestStructureSection,
    DataDescSection,
    SampleCodeSection,
    HTTPStatusSection,
    CacheSection,
    ResponseSchemaSection,
    SupportSection,
    RelatedAPIsSection,
    SlideInPopup,
    JuniorMenu,
    GotoTop,
    DataclassDisplay,
    EnumDisplay,
    DropdownIcon,
    'json-preview': JSONPreview,
    LoadingPlaceholder,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      seniorMenuState: (state) => state.link.seniorMenuState,
    }),
  },

  data() {
    return {
      schema: null,
      schemaState: new RequestState(),
      pageDetailState: new RequestState(),
      pageResponsesState: new RequestState(),

      activePreviewSample: null,
      activePreviewSampleState: new RequestState(),

      activePreviewDownloadUrl: null,
      activePreviewDownloadFilename: null,
      activePreviewTitle: null,
      activePreviewDesc: null,
      activePreviewObject: null,
      placeholderStyleNumber: 0,
      isCopied: false,
      ignoreSlugs: ['country-flag-rest-api'],
    };
  },

  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
        || to.params.sport !== from.params.sport
        || to.params.page !== from.params.page
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (this.dataLoaded) {
      console.log('Mounted page', this.page.route_value, this.page.sports);
      let sameRoute = this.page.route_value === this.$route.params.page;
      sameRoute = sameRoute && this.page.sports === this.$route.params.sport;

      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
      this.scrollToElement();
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else if (!this.schema) {
      // This case comes when, comp reloaded
      // but data already comes from Vuex
      this.fetchClientSide();
    }
  },

  methods: {
    fetchData() {
      let sportValue = this.$route.params.sport;
      if ((!sportValue) && this.$route.meta && this.$route.meta.sportValue) {
        sportValue = this.$route.meta.sportValue;
      }
      const route = `/${sportValue}/docs/`;
      const routeValue = this.$route.params.page;
      return this.$store.dispatch('page/fetchActiveAPI', { route, routeValue }).catch((err) => {
        console.error('Error on fetch Active Page', err);
      });
    },

    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
      // For changing placeholder design
      this.placeholderStyleNumber = Math.floor(Math.random() * 2);
    },

    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchClientSide();
      });
    },

    fetchPageDetail() {
      this.pageDetailState.status = RequestStateStatus.Loading;
      return this.page.fetchDetail(this.$apiInstance, this.$store).then(() => {
        this.pageDetailState.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        console.error(err);
        this.pageDetailState.status = RequestStateStatus.Error;
        this.pageDetailState.ref = err;
      });
    },

    fetchPageResponses() {
      this.pageResponsesState.status = RequestStateStatus.Loading;
      return this.page.fetchResponses(this.$apiInstance, this.$store).then(() => {
        this.pageResponsesState.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        console.error(err);
        this.pageResponsesState.status = RequestStateStatus.Error;
        this.pageResponsesState.ref = err;
      });
    },

    fetchSchema() {
      this.schemaState.status = RequestStateStatus.Loading;
      return APISchema.fetch(this.$apiInstance, this.page.endpoint_key).then((schema) => {
        this.schema = schema;
        this.schemaState.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        console.error(err);
        this.schemaState.status = RequestStateStatus.Error;
        this.schemaState.ref = err;
      });
    },


    fetchClientSide() {
      if (!this.dataLoaded) {
        console.log('Page not loaded');
        return Promise.reject();
      }

      this.pageDetailState.status = RequestStateStatus.Loading;
      this.pageResponsesState.status = RequestStateStatus.Loading;
      return this.fetchSchema().then(() => {
        return this.fetchPageDetail();
      }).then(() => {
        return this.fetchPageResponses();
      });
    },


    showSchemaDefinition(defs, path) {
      this.activePreviewTitle = defs.name;
      this.activePreviewDesc = path;
      this.activePreviewObject = defs;
    },

    showResponse(response) {
      this.activePreviewTitle = response.name;
      this.activePreviewDesc = response.desc;
      this.activePreviewDownloadUrl = response.downloadUrl();
      this.activePreviewSample = response;

      this.activePreviewSampleState.status = RequestStateStatus.Loading;
      response.fetchFile(this.$apiInstance).then((resp) => {
        this.activePreviewObject = resp.response;
        this.activePreviewSampleState.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        console.error(err);
        this.activePreviewSampleState.status = RequestStateStatus.Error;
        this.activePreviewSampleState.ref = err;
      });
    },

    onJSONPreviewLevelFound() {
    },

    slideInShouldClose() {
      this.activePreviewTitle = null;
      this.activePreviewDesc = null;
      this.activePreviewSample = null;
      this.activePreviewObject = null;
      this.activePreviewDownloadUrl = null;
    },

    slideInAsksDownload() {
    },

    onSeniorMenuIconClick(state) {
      if (!state.isEnd) {
        this.closeSeniorMenu();
      } else {
        this.openSeniorMenu();
      }
    },

    onSeniorMenuClick() {
      if (this.seniorMenuState.expanded) {
        this.$store.commit('updateDailogActive', { active: false });
        this.updateSeniorLink({ animationState: SmartMorphAnimationState.Init });
        this.closeSeniorMenu();
      } else {
        this.$store.commit('updateDailogActive', { active: true });
        this.updateSeniorLink({ animationState: SmartMorphAnimationState.End });
        this.openSeniorMenu();
      }
    },

    openSeniorMenu() {
      this.updateSeniorLink({ beginCollapse: false, expanded: true });
    },

    closeSeniorMenu() {
      this.updateSeniorLink({ beginCollapse: true });

      // Wait for animation to finish
      setTimeout(() => {
        this.updateSeniorLink({ beginCollapse: false, expanded: false });
      }, 455);
    },

    updateLinkAnimationState(animationState) {
      this.updateSeniorLink({ animationState });
    },

    updateSeniorLink(newState) {
      this.$store.commit('link/updateSeniorMenuState', newState);
    },
    copyUrl(target) {
      if (document) {
        const element = document.createElement('textarea');
        element.value = `${window.location.href}#${target}`;
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 1000);
      }
    },
    scrollToElement() {
      if (window) {
        const url = window.location.href;
        if (url && url.includes('#')) {
          const sectionId = url.split('#')[1];
          const scroll = document.getElementById(sectionId);
          scroll.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
  },
};
</script>
