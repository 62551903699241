<template>
  <div class="api-sample-code">
    <div class="page-tabs white-bg" v-if="codeGroups.length > 1">
      <ul>
        <li
        v-for="(codeGroup, i) in codeGroups"
        :key="i"
        :class="{active: i === activeGroupIndex}"
        >
          <a :title="codeGroup.name" @click="activeGroupIndex=i">{{codeGroup.name}}</a>
        </li>
      </ul>
    </div>
    <div class="code-group">
      <SampleCodeGroup :codeGroup="codeGroups[activeGroupIndex]" />
    </div>
  </div>
</template>
<style lang="scss">
.api-sample-code {
  color: var(--secondary-text);
  .page-tabs {
    a {
      cursor: pointer;
    }
  }
}
</style>
<script>
import SampleCodeGroup from '@/components/CodeDisplay/CodeGroup.vue';

export default {
  components: { SampleCodeGroup },
  props: {
    codeGroups: Array,
  },
  data() {
    return {
      activeGroupIndex: 0,
    };
  },
};
</script>
