<template>
  <div class="api-data-res-schema">
    <div class="loader" v-if="!state.status.isLoaded">
      <rz-request-state :state="state" />
    </div>
    <div v-else>
      <div :class="{'expanded': isExpanded}">
        <div class="page-margin" :class="[!enableExpandButton ? 'page-space-bottom':null]">
          <img class="shrink" @click="toggleExpand()" :class="isExpanded ? 'collapse' : 'hide'"
            src="@/assets/collapse.svg">
          <div id="response-container" class="schema-container">
            <div ref="schemaViewEle">
              <dataclass-display
                :class="[ isExpanded ? 'json-space-bottom' : null]"
                @showSchemaDefinition="showSchemaDefinition"
                :dataclass="schema.definition.response"
                :defs="schema.defs"
              />
            </div>
          </div>
        </div>
        <div class="expand-bar" v-if="enableExpandButton">
          <div @click="toggleExpand()" class="expand-bar-container">
            <img class="expand-icon" :class="[isExpanded ? '' : 'show-expand']"
            src="@/assets/expand-icon.svg" alt="Expand Icon">
            <img class="collapse-icon" :class="[!isExpanded ? '' : 'show-close']"
            src="@/assets/collapse.svg" alt="Collapse Icon">
            <button  class="btn secondary no-border" aria-label="Expand">
              {{(isExpanded) ? 'Close' : 'Expand'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .api-data-res-schema {
    --expand-bar-color: #DEEDED;
    --expanded-bar-pad-top: #{size(20)};

    @media screen and (min-width: $breakpoint-md) {
      --expanded-bar-pad-top: #{size(40)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --expanded-bar-pad-top: #{size(64)};
    }

    --expanded-bar-height: #{size(45)};
    transition: all 1s ease-in-out;

    .schema-container {
      max-height: size(430);
      width: 100%;
      overflow: hidden;
    }
    .page-margin {
      &.add-padding {
        padding-bottom: var(--section-box-content-pad-top)!important;
      }
    }
    .expand-bar {
      background-color: var(--expand-bar-color);
      padding: 0 size(26);
      cursor: pointer;
      .show-close{
        display: inline-block!important;
      }
      .show-expand {
        display: inline-block !important;
      }
      .expand-bar-container {
        &.remove-expand-bar {
          display: none;
        }
        button {
          display: inline-block;
          padding: size(15) 0 size(15) size(8);
          outline: none;
          text-decoration : none;
          @media screen and (min-width: $breakpoint-md) {
            padding: size(16) 0 size(16) size(8);
          }
        }
        .collapse-icon , .expand-icon {
          display: none;
          vertical-align: top;
          cursor: pointer;
          margin-top: size(12);
          width: size(20);
          height: size(20);
          @media screen and (min-width: $breakpoint-md) {
            margin-top: size(7);
            width: size(30);
            height: size(30);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-top: size(12);
            width: size(20);
            height: size(20);
          }
        }
      }
    }
    .page-margin {
      padding-right: 0;
      .collapse {
        display: inline-block;
        position: absolute;
        top: size(24);
        right: size(16);
        cursor: pointer;
        @media screen and (min-width: $breakpoint-md) {
          top: size(36);
          right: size(56);
        }
        @media screen and (min-width: $breakpoint-lg) {
          top: size(48);
          right: size(80);
        }
      }
      .hide {
        display: none;
      }
    }
    .expanded {
      position: fixed;
      z-index: var(--dailog-z-index);
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: var(--box-color-2);

      .expand-bar {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
      }

      .page-margin {
        padding-bottom: var(--expanded-bar-height) !important;
        height: 100%;
        padding-top: var(--expanded-bar-pad-top)!important;
      }

      .schema-container {
        height: calc(100% - var(--expanded-bar-height));
        max-height: none;
        overflow: auto;
        & .json-space-bottom{
          padding-bottom: var(--json-preview-space-bottom);
        }
      }
    }
  }
</style>

<script>
import DataclassDisplay from '@/components/CodeDisplay/DataclassDisplay.vue';

export default {
  components: { DataclassDisplay },
  props: {
    state: Object,
    schema: Object,
    page: Object,
  },
  data() {
    return {
      isExpanded: false,
      enableExpandButton: true,
    };
  },
  updated() {
    if (this.state.status.isLoaded && !this.isExpanded) {
      this.displayExpandButton();
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      const container = document.getElementById('response-container');
      container.scroll({
        top: 0,
      });
      this.$store.commit('updateDailogActive', { active: this.isExpanded });
    },
    showSchemaDefinition(defs, path) {
      this.$emit('showSchemaDefinition', defs, path);
    },
    displayExpandButton() {
      if (this.$refs.schemaViewEle.offsetHeight < 430) {
        this.enableExpandButton = false;
      } else {
        this.enableExpandButton = true;
      }
    },
  },
};
</script>
