<template>
  <div class="api-data-desc">
    <div class="loader" v-if="!state.status.isLoaded">
      <rz-request-state :state="state" />
    </div>
    <div v-else-if="page && page.detail">
      <template v-for="(dataDesc, i) in page.detail[itemKey]">
        <div v-if="typeof dataDesc === 'object'" :key="i" class ="data-desc  box-content-item">
          <h3 v-if="dataDesc.name">{{dataDesc.name}}</h3>
          <p class="para-level-3" v-if="dataDesc.desc"><rz-markdown :text="dataDesc.desc" /></p>
          <div class="data-desc-links" :class="{'content-level-3': dataDesc.desc}">
            <div v-if="dataDesc.items && dataDesc.items.length">
              <div
                class="item-desc"
                v-for="(item, i) in dataDesc.items"
                :key="i">
                <rz-json-path :text="item" />
              </div>
            </div>
            <div v-if="dataDesc.links && dataDesc.links.length">
              <rz-link-item
                v-for="(link, i) in dataDesc.links"
                :key="i"
                :link="link" />
            </div>
            <div
              class="response-items"
              :class="{'use-column': itemKey === 'downloads'}"
              v-if="dataDesc.responses_keys && dataDesc.responses_keys.length">
              <div
                v-for="(rk, i) in dataDesc.responses_keys"
                :key="i"
              class="response-item"
              >
              <div v-if="!responseState.status.isLoaded && !page.responses">...</div>
              <div v-else>
                <PreviewButton v-if="page.responses[rk]" @click="openPreview(page.responses[rk])"
                  :text="page.responses[rk].name" />
              </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss">
  .api-data-desc {
    .response-items {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;

      .response-item {
        padding-bottom: size(8);
        .preview-button {
          svg {
            width: size(20);
            height: size(20);
            min-width: size(20);
            @media screen and (min-width: $breakpoint-md) {
              width: size(30);
              height: size(30);
            }
          }
        }
      }

      &.use-column {
        flex-direction: row;

        .response-item {
          padding-right: 0;
          padding-bottom: size(16);
          width: 100%;
          @media screen and (min-width: $breakpoint-md) {
            padding-right: size(35);
            width: auto;
          }
        }
      }
    }

    .item-desc {
      padding-bottom: size(20);

      p, .variable-box {
        color: var(--hero-text);
        text-transform: uppercase;

        font-size: size(12) !important;
        line-height: 1 !important;
        letter-spacing: 0.09em !important;
      }

      .variable-box {
        color: inherit;
        padding: size(1) size(3);
        border-radius: size(2);
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
</style>
<script>
import PreviewButton from '@/components/PreviewButton.vue';

export default {
  components: { PreviewButton },
  props: {
    state: Object,
    responseState: Object,
    page: Object,
    itemKey: String,
  },
  methods: {
    openPreview(response) {
      this.$emit('showResponse', response);
    },
  },
};
</script>
