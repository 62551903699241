<template>
  <span class="type-display">
    <span class="type-collection-display" v-if="typeExplain.isCollection">
      <span class="type-collection type-collection-wrapper">{{typeExplain.collectionType}}(</span>
      <span
       class="type-name"
       v-for="(name, i) in typeExplain.typeNames"
       :key="i">
        <span v-if="i > 0">,</span>
        <span  @click="showDef(name.key)" v-if="name.isDataClass" class="type-name link">{{name.name}}</span>
        <span  @click="showDef(name.key)" v-else-if="name.isEnum" class="type-name link enum">{{name.name}}</span>
        <span v-else class="type-name" :class="name.name">{{name.name}}</span>
      </span>
      <span class="type-collection-wrapper">)</span>
    </span>
    <span v-else>
      <span
       class="type-name"
       v-for="name in typeExplain.typeNames"
       :key="name.name">
        <span  @click="showDef(type)" v-if="name.isDataClass" class="type-name link">{{name.name}}</span>
        <span  @click="showDef(type)" v-else-if="name.isEnum" class="type-name link enum">{{name.name}}</span>
        <span v-else class="type-name" :class="name.name">{{name.name}}</span>
      </span>
    </span>
  </span>
</template>
<style lang="scss">
.type-display {

}
</style>
<script>
export default {
  props: {
    type: String,
    defs: Object,
  },
  data() {
    return { typeExplain: this.buildTypeExplain() };
  },
  methods: {
    typeDesc(txt) {
      const def = this.defs[txt];
      const parts = txt.split('.');
      // const isDataClass = parts[0].startsWith('roanuz');
      const name = parts.slice(1).join('.');

      return {
        key: txt,
        name,
        hasDef: !!(def),
        isDataClass: def && def.typ === 'dataclass',
        isEnum: def && def.typ === 'enum',
      };
    },
    buildTypeExplain() {
      let parts = this.type.split(':');
      const isCollection = parts.length > 1;
      let collectionType = null;
      let typeNames = [];

      if (isCollection) {
        [, collectionType] = parts;
        parts = parts.slice(2);
        for (let i = 0; i < parts.length; i += 1) {
          typeNames.push(this.typeDesc(parts[i]));
        }
      } else {
        typeNames = [this.typeDesc(this.type)];
      }

      return {
        type: this.type,
        typeNames,
        collectionType,
        isCollection,
      };
    },
    showDef(path) {
      this.$emit('showSchemaDefinition', this.defs[path], path);
    },
  },
};
</script>
