<template>
  <div
    class="json-preview dataclass-wrapper compact-width"
    :class="[
      level > 0? 'child-level' : 'root-level',
      'level-' + level,
      (preview.expanded) ? 'expanded': 'collapsed',
    ]"
    >
    <ul>
      <li
        v-for="item in preview.val"
        :key="item.key"
        class="field dataclass__field__line"
      >
        <span
          class="name required"
          :class="{array: preview.isArray, exapandable: item.hasChildren}"
          @click="toggleExpand(item)"
        >{{item.key}}:</span>
        <template v-if="item.hasChildren">
          <span class="type value">
            <span
            class="defined empty object"
            v-if="item.isArray && !item.val.length">[ARRAY] (empty)</span>
            <span class="defined empty object" v-else-if="item.isObject && !item.val.length">{ empty }</span>
            <span class="defined expand-btn" @click="toggleExpand(item)" v-else-if="!item.expanded">
              <img src="@/assets/json-expand-icon.svg">
            </span>
            <span class="defined array" v-else-if="item.isArray">[ARRAY]</span>
            <span class="defined object" v-else></span>
          </span>
          <json-preview
          :prasedSrc="item"
          :level="level+1"
          :sampleQuery="true"
          :skipPrefix="skipPrefix"
          :defaultExpandLevel="defaultExpandLevel"
          :parentPath="childPath(item.key)"/>
        </template>
        <span
        v-else
        :class="[typeClass(item.val)]"
        class="type value">
          <span clas="defined" v-if="detectType(item.val) === 'null'">null</span>
          <span clas="defined" v-else-if="detectType(item.val) === 'undefined'">undefined</span>
          <span class="empty defined" v-else-if="item.val === ''">(empty)</span>
          <span :alt="parentPath">{{item.val}}</span>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import '@/assets/styles/components/json_preview.scss';

export class PreviewItem {
  constructor({
    key,
    val,
    orginialObject,
    expanded,
  }) {
    this.key = key;
    this.val = val;
    this.expanded = expanded;
    this.isArray = PreviewItem.isItArray(orginialObject);
    this.isObject = (!this.isArray) && PreviewItem.isItObject(orginialObject);
    this.hasChildren = this.isArray || this.isObject;
  }

  static isItArray(val) {
    return Array.isArray(val);
  }

  static isItObject(val) {
    return Object.prototype.toString.call(val) === '[object Object]';
  }
}

export default {
  name: 'json-preview',
  props: {
    src: {
      type: Object,
      default: null,
    },
    prasedSrc: {
      type: Object,
      default: null,
    },
    level: {
      type: Number,
      default: 0,
    },
    defaultExpandLevel: {
      type: Number,
      default: 4,
    },
    skipPrefix: {
      type: Array,
      default: () => {
        return ['$', '_'];
      },
    },
    parentPath: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      preview: (this.prasedSrc) ? this.prasedSrc : this.preparePreviewObject('', this.src, this.level),
    };
  },

  methods: {
    preparePreviewObject(key, obj, level) {
      if (obj instanceof PreviewItem) {
        return obj;
      }

      if (!this.levelTracker) {
        this.levelTracker = { levels: -1 };
      }

      if (level > this.levelTracker.levels) {
        this.levelTracker.levels = level;
      }

      let val = obj;
      const nextLevel = level + 1;

      if (PreviewItem.isItArray(obj)) {
        val = obj.map((x, k) => this.preparePreviewObject(k, x, nextLevel));
      } else if (PreviewItem.isItObject(obj)) {
        const keys = Object.keys(obj).filter((k) => {
          const found = this.skipPrefix.findIndex((prefix) => k.startsWith(prefix));
          return found === -1;
        });

        val = keys.map((k) => this.preparePreviewObject(k, obj[k], nextLevel));
      }

      const expanded = level < this.defaultExpandLevel;
      const parsed = new PreviewItem({
        key, val, level, expanded, orginialObject: obj,
      });

      if (key === '') {
        this.emitMaxLevel();
      }

      return parsed;
    },

    childPath(key) {
      if (this.parentPath) {
        return `${this.parentPath}.${key}`;
      }
      return key;
    },

    detectType(val) {
      if (val === null) {
        return 'null';
      }

      if (val === undefined) {
        return 'undefined';
      }

      return typeof val;
    },

    typeClass(val) {
      return `value-type-${this.detectType(val)}`;
    },

    toggleExpand(obj) {
      if (obj.hasChildren) {
        this.$set(obj, 'expanded', !obj.expanded);
      }
    },

    emitMaxLevel() {
      this.$emit('levelsFound', this.levelTracker.levels);
    },
  },
};

</script>
