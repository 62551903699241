<template>
  <div class="version-block-outer">
    <div :class="['app-version', infraEnv]" v-if="(infraEnv !== 'production')">
      <div class="versions" v-if="show">
        <ul>
          <li>Ver: <b>{{appVersion}}</b></li>
          <li v-if="instance">Backend Env: <b>{{instance.backend_run_env}}</b></li>
          <li v-if="instance">Backend Ver: <b>{{instance.backend_version}}</b></li>
        </ul>
      </div>
      <div>
        <router-link
          class="btn on-bright-bg size-tiny"
          :to="{name: 'docsGuide', params: {sport: 'cricket', page: 'version'}}"
          :exact-active-class="'active'">
          <slot>
            <svg xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" viewBox="0 0 100 100">
              <title>info</title>
              <desc>Created with Sketch.</desc>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                <g class="cls2" sketch:type="MSLayerGroup" fill="#000000">
                  <path d="M50,94 C74.300529,94 94,74.300529 94,50 C94,
                  25.699471 74.300529,6 50,6 C25.699471,6 6,25.699471 6,50 C6,
                  74.300529 25.699471,94 50,94 L50,94 Z M50,86 C69.882251,86 86,
                  69.882251 86,50 C86,30.117749 69.882251,14 50,14 C30.117749,
                  14 14,30.117749 14,50 C14,69.882251 30.117749,86 50,86 L50,
                  86 Z M45,49.0044356 C45,46.2405621 47.2441952,44 50,
                  44 C52.7614237,44 55,46.2303666 55,49.0044356 L55,68.9955644 C55,
                  71.7594379 52.7558048,74 50,74 C47.2385763,74 45,71.7696334 45,
                  68.9955644 L45,49.0044356 L45,49.0044356 Z M44,32 C44,
                  28.6862915 46.6930342,26 50,26 C53.3137085,26 56,28.6930342 56,
                  32 C56,35.3137085 53.3069658,38 50,38 C46.6862915,38 44,35.3069658 44,
                  32 L44,32 Z" sketch:type="MSShapeGroup"></path>
                </g>
              </g>
            </svg>
            <span>V5 API {{infraEnv}}</span>
          </slot>
        </router-link>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.version-block-outer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: size(10);
  z-index: 100;
  .app-version{
    --env-bg-color: #333;
    --env-text-color: #DEDEDE;
    &.dev {
      --env-bg-color: #EEAA66;
      --env-text-color: #5E3C1B;
    }
    display: flex;
    align-items: center;
    flex-flow: column;
    .versions {
      flex: 1;
      font-size: size(12);
      ul {
        color: var(--env-bg-color);
        background-color: var(--env-text-color);
        padding: size(3);
        width: size(130);
        li {
          line-height: size(20);
        }
      }
    }
    .btn {
      background-color: var(--env-bg-color);
      color: var(--env-text-color);
      border: none;
      line-height: size(14);
      svg {
        width: size(16);
        vertical-align: middle;
        margin-top: -size(2);
        .cls2 {
          fill: var(--env-text-color);
        }
      }
      span {
        display: inline-block;
        vertical-align: middle;
        padding-left: size(4);
      }
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';
import Config from '@/config';

export default {
  computed: {
    ...mapState({
      instance: (state) => state.boot.instance,
    }),
  },
  data() {
    return {
      show: false,
      infraEnv: Config.infraEnv,
      env: Config.env,
      appVersion: Config.version,
    };
  },
};
</script>
