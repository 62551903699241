<template>
  <div class="links-section">
    <h2 v-if="title" class="title search-wrapper">{{title}}</h2>
    <div class="search-box" v-if="isSearchable">
      <div class="search-wrapper">
        <input v-model="searchInput" type="text" :placeholder="'Search ' + title" />
        <img src="@/assets/search-icon.svg" alt="Search" />
      </div>
    </div>
    <div :class="['api-links-section', isSearchable ? '' : 'guide']">
      <div class="links-wrapper">
        <div :class="['cards', isSearchable ? '' : 'guide']"
          v-for="link in filteredList" :key="link.name">
          <rz-link-item  class="link-comp" :link="link" >
            <span class="card-title">{{link.name}}</span>
            <span class="desc-area" v-if="link.desc">
              <rz-markdown :text="link.desc"></rz-markdown>
            </span>
          </rz-link-item>
        </div>
        <p class="error-msg" v-if="filteredList.length === 0">
          Sorry! We're not able to find the
          <span v-if="$route.meta.routeValue === 'docs-home'">API Endpoint</span>
          <span v-else>Query</span>
            that you are looking for.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchInput: '',
    };
  },
  props: {
    linksList: Object,
    isSearchable: Boolean,
    title: String,
  },
  computed: {
    /* eslint-disable operator-linebreak */
    filteredList() {
      if (
        this.linksList &&
        this.linksList.allLinkData &&
        this.linksList.allLinkData.length > 0
      ) {
        return this.linksList.allLinkData.filter((link) => {
          return link.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase());
        });
      }
      return [];
    },
  },
};
</script>
<style lang="scss">
.links-section {
  --box-description-font-size: #{size(14)};
  --box-description-line-height: #{size(17)};

  --subtitle-font-size: #{size(18)};
  --subtitle-line-height: #{size(24)};

  --endpoint-title-font-size: #{size(28)};
  --endpoint-title-line-height: #{size(37)};

  --endpoint-padding-top: #{size(60)};
  --endpoint-padding-btm: #{size(20)};
  --endpoint-padding-left: #{size(16)};

  --senior-menu-width: inherit;

  --search-pad-top: #{size(9)};
  --search-pad-right: #{size(32)};
  --search-pad-bottom: #{size(9)};
  --search-pad-left: #{size(32)};
  --search-svg-width: #{size(30)};
  --search-svg-height: #{size(30)};
  --search-calc-width: #{size(45)};
  --search-input-width: calc(100% - var(--search-calc-width));

  --links-sec-pad-top: #{size(24)};
  --links-sec-pad-right: #{size(26)};
  --links-sec-pad-bottom: #{size(24)};
  --links-sec-pad-left: #{size(27)};
  --links-box-margin-bottom: #{size(24)};
  --links-box-margin-side: #{size(0)};
  --cards-pad: #{size(24)};
  --cards-height: #{size(100)};
  --cards-api-title-margin: #{size(12)};
  --cards-title-margin: #{size(8)};

  @media screen and (min-width: $breakpoint-md) {
    --endpoint-title-font-size: #{size(52)};
    --endpoint-title-line-height: #{size(69)};
    --endpoint-padding-left: #{size(36)};
    --endpoint-padding-top: #{size(80)};
    --endpoint-padding-btm: #{size(36)};

    --subtitle-font-size: #{size(28)};
    --subtitle-line-height: #{size(35)};

    --box-description-font-size: #{size(16)};
    --box-description-line-height: #{size(19)};

    --senior-menu-width: #{size(160)};

    --search-pad-top: #{size(15)};
    --search-pad-right: #{size(36)};
    --search-pad-bottom: #{size(15)};
    --search-pad-left: #{size(36)};
    --search-svg-width: #{size(40)};
    --search-svg-height: #{size(40)};
    --search-calc-width: #{size(55)};
    --search-input-width: calc(100% - var(--search-calc-width));

    --links-sec-pad-top: #{size(32)};
    --links-sec-pad-right: #{size(20)};
    --links-sec-pad-bottom: #{size(32)};
    --links-sec-pad-left: #{size(20)};
    --links-box-margin-bottom: #{size(12)};
    --links-box-margin-side: #{size(6)};
    --cards-pad: #{size(28)};
    --cards-height: #{size(182)};
    --cards-api-title-margin: #{size(12)};
    --wrapper-max-width: #{size(700)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --endpoint-title-font-size: #{size(76)};
    --endpoint-title-line-height: #{size(92)};
    --endpoint-padding-top: #{size(100)};
    --endpoint-padding-btm: #{size(74)};
    --endpoint-padding-left: #{size(80)};

    --subtitle-font-size: #{size(38)};
    --subtitle-line-height: #{size(46)};

    --box-description-font-size: #{size(18)};
    --box-description-line-height: #{size(24)};

    --senior-menu-width: #{size(204)};

    --search-pad-right: #{size(117)};
    --search-pad-left: #{size(123)};
    --links-sec-pad-top: #{size(74)};
    --links-sec-pad-right: #{size(0)};
    --links-sec-pad-bottom: #{size(74)};
    --links-sec-pad-left: #{size(96)};
    --links-box-pad-bottom: #{size(24)};
    --links-box-margin-bottom: #{size(48)};
    --links-box-margin-side: #{size(24)};
    --cards-pad: #{size(28)};
    --cards-height: #{size(220)};
    --cards-api-title-margin: #{size(20)};
    --wrapper-max-width: #{size(1400)};
  }

  .title {
    font-size: var(--endpoint-title-font-size);
    line-height: var(--endpoint-title-line-height);
    color: var(--hero-text);
    font-family: var(--hero-font);
    padding: var(--endpoint-padding-top) var(--endpoint-padding-left)
      var(--endpoint-padding-btm);
    border-bottom: size(1) solid var(--gray-line);
    @media screen and (min-width: $breakpoint-md) {
      font-size: var(--endpoint-title-font-size);
      line-height: var(--endpoint-title-line-height);
    }
  }
  .links-wrapper,
  .hero-wrapper {
    max-width: var(--wrapper-max-width);
    margin: 0 auto;
  }
  .search-box {
    background-color: var(--regular-text-inv);
    padding: var(--search-pad-top) var(--search-pad-right)
      var(--search-pad-bottom) var(--search-pad-left);
    input {
      border: size(0);
      outline: none;
      display: inline-block;
      vertical-align: middle;
      margin-top: size(5);
      margin-right: size(5);
      font-size: var(--box-description-font-size);
      line-height: var(--box-description-line-height);
      color: var(--secondary-text);
      font-family: var(--regular-font);
      width: var(--search-input-width);
      cursor: pointer;
      transition: color 0.3s linear;
      &:focus {
        &::placeholder {
          color: #d8d8d8;
        }
      }
      &::placeholder {
        font-size: var(--box-description-font-size);
        line-height: var(--box-description-font-size);
        color: var(--secondary-text);
        font-family: var(--regular-font);
      }
    }
    img {
      display: inline-block;
      vertical-align: middle;
      width: var(--search-svg-width);
      height: var(--search-svg-height);
      cursor: pointer;
    }
  }
  .api-links-section {
    &:not(.guide) {
      background-color: var(--box-color-2);
      padding: var(--links-sec-pad-top) var(--links-sec-pad-right)
        var(--links-sec-pad-bottom) var(--links-sec-pad-left);
    }
    &.guide {
      padding: var(--links-sec-pad-top) size(16) var(--links-sec-pad-bottom)
        size(16);
      .links-wrapper {
        .cards {
          padding: 0;
          min-height: 0;
          .markdown {
            span.p {
              margin-top: size(8);
              margin-bottom: 0;
            }
          }
          .link-item {
            position: static;
            &.link-comp {
              .link-router, .link-placeholder, .link-plain {
                padding: 0;
                height: auto;
              }
            }
          }
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        padding-left: size(22);
        padding-right: 0;
        .links-wrapper {
          .cards {
            margin: 0 size(16) size(32) size(16);
            .markdown {
              span.p {
                margin-top: size(12);
              }
            }
          }
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-left: size(96);
        padding-right: 0;
        .links-wrapper {
          .cards {
            margin: 0 size(44) size(36) size(44);
            .markdown {
              span.p {
                margin-top: size(16);
              }
            }
          }
        }
      }
    }
    .links-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (min-width: $breakpoint-lg) {
        justify-content: unset;
      }
      .error-msg {
        font-size: var(--box-description-font-size);
        line-height: var(--box-description-line-height);
        color: var(--secondary-text);
        font-family: var(--regular-font);
        margin-top: var(--cards-title-margin);
        margin-bottom: var(--cards-api-title-margin);
      }
      .cards {
        flex: 100%;
        background-color: var(--regular-text-inv);
        margin-bottom: var(--links-box-margin-bottom);
        cursor: pointer;
        text-align: left;
        padding: var(--cards-pad);
        min-height: var(--cards-height);
        position: relative;
        @media screen and (min-width: $breakpoint-md) {
          flex: 0 0 37%;
          max-width: size(282);
          margin: size(0) var(--links-box-margin-side)
            var(--links-box-margin-bottom);
        }
        @media screen and (min-width: $breakpoint-lg) {
          flex: 0 0 38%;
          max-width: size(376);
          margin: size(0) var(--links-box-margin-side)
            var(--links-box-margin-bottom);
        }
        .markdown {
          span.p {
            font-size: var(--box-description-font-size);
            line-height: var(--box-description-line-height);
            color: var(--secondary-text);
            font-family: var(--regular-font);
            margin-top: var(--cards-title-margin);
            margin-bottom: var(--cards-api-title-margin);
            font-weight: normal;
          }
        }
        .link-item {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          &.link-comp {
            .link-router,
            .link-plain,
            .link-placeholder {
              display: inline-block;
              padding: var(--cards-pad);
              height: var(--cards-height);
              .card-title {
                font-family: var(--hero-font);
                font-size: var(--subtitle-font-size);
                line-height: var(--subtitle-line-height);
                color: var(--hero-text);
                padding-bottom: size(2);
                font-weight: bold;
                background-image: linear-gradient(
                  var(--hero-text),
                  var(--hero-text)
                );
                background-position: 0% 100%;
                background-repeat: no-repeat;
                background-size: 0% size(2);
                transition: background-size 0.3s;
              }
              &:hover .card-title{
                background-size: 100% size(2);
              }
            }
          }
        }
      }
    }
  }
}
</style>
