/* eslint-disable */
export const cricketProductsInfo = {
  basic: {
    match: [
      'All Live Matches from MG100 & MG100B Coverage',
      'Three Recently Completed and Upcoming Three Matches from MG100 & MG100B Coverage',
    ],
    schedule: [
      'Current Month Matches From MG100 & MG100B Coverage',
    ],
    api: [
      { name: 'Match API', link: '/docs/Core-API/Match-API/' },
      { name: 'Schedule API', link: '/docs/Core-API/Schedule-API/' },
      { name: 'News Agg. API', link: '/docs/Core-API/News-Aggregation-API/' },
      { name: 'Recent Season API', link: '/docs/Core-API/Recent-Seasons-API/' },
      { name: 'Recent Matches API', link: '/docs/Core-API/Recent-Matches-API/' },
    ],
  },
  pro: {
    match: [
      'All Live Matches from MG100 & MG100B Coverage',
      'Three Recently Completed and Upcoming Three Matches from MG100 & MG100B Coverage',
    ],
    schedule: [
      'Current Month Matches From MG100 & MG100B Coverage',
    ],
    api: [
      { name: 'Match API', link: '/docs/Core-API/Match-API/' },
      { name: 'Schedule API', link: '/docs/Core-API/Schedule-API/' },
      { name: 'News Agg. API', link: '/docs/Core-API/News-Aggregation-API/' },
      { name: 'Recent Season API', link: '/docs/Core-API/Recent-Seasons-API/' },
      { name: 'Recent Matches API', link: '/docs/Core-API/Recent-Matches-API/' },
    ],
  },
  business: {
    match: [
      'All Live Matches from MG100 & MG100B Coverage',
      'Three Recently Completed and Upcoming Three Matches from MG100 & MG100B Coverage',
    ],
    schedule: [
      'Current Month Matches From MG100 & MG100B Coverage'
    ],
    api: [
      { name: 'Match API', link: '/docs/Core-API/Match-API/' },
      { name: 'Schedule API', link: '/docs/Core-API/Schedule-API/' },
      { name: 'News Agg. API', link: '/docs/Core-API/News-Aggregation-API/' },
      { name: 'Recent Matches API', link: '/docs/Core-API/Recent-Matches-API/' },
      { name: 'Recent Season API', link: '/docs/Core-API/Recent-Seasons-API/' },
      { name: 'Stats API', link: '/docs/Core-API/Season-Stats-API/' },
      { name: 'Season API', link: '/docs/Core-API/Season-API/' },
      { name: 'Points Table API', link: '/docs/Core-API/Season-Points-API/' },
      { name: 'Season Team API', link: '/docs/Core-API/Season-Team-API/' },
      { name: 'Balls API', link: '/docs/Core-API/Ball-By-Ball-API/' },
      { name: 'Over summary API', link: '/docs/Core-API/Overs-Summary-API/'},
    ],
  },
  enterprise: {
    match: [
      'All Live Matches from MG100 & MG100B Coverage',
      'All MG100 & MG100B Matches from Current Month',
      'Current Month Matches from MG100 & MG100B Coverage',
    ],
    schedule: [
      'All MG100 & MG100B Matches from Previous 2 Months and 5 Upcoming Months',
      'Two Previous and 5 Upcoming Months Matches from MG100 & MG100B Coverage',
    ],
    api: [
      { name: 'Match API', link: '/docs/Core-API/Match-API/' },
      { name: 'Schedule API', link: '/docs/Core-API/Schedule-API/' },
      { name: 'News Agg. API', link: '/docs/Core-API/News-Aggregation-API/' },
      { name: 'Recent Matches API', link: '/docs/Core-API/Recent-Matches-API/' },
      { name: 'Recent Season API', link: '/docs/Core-API/Recent-Seasons-API/' },
      { name: 'Stats API', link: '/docs/Core-API/Season-Stats-API/' },
      { name: 'Season API', link: '/docs/Core-API/Season-API/' },
      { name: 'Points Table API', link: '/docs/Core-API/Season-Points-API/' },
      { name: 'Season Team API', link: '/docs/Core-API/Season-Team-API/' },
      { name: 'Balls API', link: '/docs/Core-API/Ball-By-Ball-API/' },
      { name: 'Over summary API', link: '/docs/Core-API/Overs-Summary-API/' },
      { name: 'Player Stats API', link: '/docs/Core-API/Player-Stats-API/'},
    ],
    push: [
      'When a live event occurs, we will push the live feeds to your server. You can receive the live feeds through Webhook, Websocket, or Firebase. The usage request will not be consumed for the push notifications.',
    ],
    priority_suppport: [
      'Quick and dedicated support.',
    ],
    beta: [
      'Free access to all Beta API.',
    ],
    server: [
      'Can access data from server side.',
    ],
  },
  fantasy_silver: {
    match: [
      'All Live Matches from MG100 & MG100B Coverage',
      'Past 10 Days Matches from MG100 & MG100B Coverage',
      'Upcoming 10 Days Matches from MG100 & MG100B Coverage',
    ],
    schedule: [
      'Current Month from MG100 & MG100B Coverage',
    ],
    api: [
      { name: 'Fantasy Match Credit API ', link: '/docs/Fantasy/Fantasy-Match-Credit-API/' },
      { name: 'Fantasy Match Points API ', link: '/docs/Fantasy/Fantasy-Match-Points-API/' },
      { name: 'Schedule API', link: '/docs/Core-API/Schedule-API/' },
    ],
  },
  fantasy_gold: {
    match: [
      'All Live Matches from MG100 & MG100B Coverage',
      'Past 1 Month Matches from MG100 & MG100B Coverage',
      'Upcoming 1 Month Matches from MG100 & MG100B Coverage',
    ],
    schedule: [
      'Current Month from MG100 & MG100B Coverage',
      'One Previous and Upcoming Month from MG100 & MG100B Coverage',
    ],
    api: [
      { name: 'Fantasy Match Credit API ', link: '/docs/Fantasy/Fantasy-Match-Credit-API/' },
      { name: 'Fantasy Match Points API ', link: '/docs/Fantasy/Fantasy-Match-Points-API/' },
      { name: 'Schedule API', link: '/docs/Core-API/Schedule-API/' },
      { name: 'Player Stats API', link: '/docs/Core-API/Player-Stats-API/' },
      { name: 'Season API ', link: '/docs/Core-API/Season-API/' },
      { name: 'Season Team API ', link: '/docs/Core-API/Season-Team-API/' },
      { name: 'Match API', link: '/docs/Core-API/Match-API/' },
      { name: 'Fantasy Credit Variation API', link: '/docs/Fantasy/Fantasy-Credit-Variation-API/'}
    ],
    editor: [
      'Fantasy Credit Editor',
    ],
  },
  chatbot: {
    api: 'Get statistical answers for Cricket related questions',
    coverage_detail: 'Based on ICC International Matches from 2012 & IPL, BBL, PSL, CPL, BPL, T20 Matches and more leagues around the world and All Tournaments and Leagues from the coverage of MG100 and MG100B'
  },
  performance: {
    api: [
      { name: 'Team Timeline API', link: '/docs/Bot-And-Performance/Team-Timeline-API/' },
      { name: 'Player Timeline API', link: '/docs/Bot-And-Performance/Player-Timeline-API/' },
      { name: 'Player Performance API', link: '/docs/Bot-And-Performance/Player-Performance-API/' },
      { name: 'Team Performance API', link: '/docs/Bot-And-Performance/Team-Performance-API/' },
      { name: 'Player vs Team Performance API ', link: '/docs/Bot-And-Performance/Player-Vs-Team-Performance-API/'}
    ],
    coverage_detail: 'Based on ICC International Matches from 2012 & IPL, BBL, PSL, CPL, BPL, T20 Matches and more leagues around the world and All Tournaments and Leagues from the coverage of MG100 and MG100B'
  },
}

export const footballProductsInfo = {
  basic: {
    match: [
      'All Live Matches',
      'Recently Completed 20 Matches',
      'Upcoming 20 Matches',
    ],
    schedule: [
      'Current Month',
    ],
    api: [
      { name: 'Match API', link: '/docs/Core-API/Match-API/' },
      { name: 'Recent Tournament API', link: '/docs/Core-API/Recent-Tournament-API/' },
      { name: 'Tournament API', link: '/docs/Core-API/Tournament-API/' },
      { name: 'Tournament Round API', link: '/docs/Core-API/Tournament-Round-API/'}
    ],
  },
}

export const productsGroupDesc = {
  cricket: {
    live_score_stats: {
      name: 'Live Score API & Statistics',
      desc: 'Powering your App with Live Cricket Data can work wonders! Choose a plan that fits your needs and exceed performance expectations. Simple pricing. No contracts or commitments. Just sign up and start building!',
      link: 'live-score-stats-api',
    },
    enterprise: {
      name: 'Cricket Enterprise Plan',
      desc: 'Priority support and push notification for faster data delivery. You never run out server requests. Data archive on demand. Best suit for enterprise and high precision score updates. Free access to all Beta access. We ensure 99.9% API Uptime and fastest data delivery. And get that on SLA with Enterprise License.',
      link: 'enterprise',
    },
    fantasy: {
      name: 'Cricket Fantasy API',
      desc: 'Readymade APIs for building best-in-class Cricket Fantasy Apps. Receive A.I. powered Player Credit Value (Player Market Value) and User points calculation API for organising scientifically backed, reliable fantasy contests.',
      link: 'fantasy-api',
    },
    chatbot: {
      name: 'Chatbot API for Cricket',
      desc: 'With the Chatbot API, now build chatbots that can engage in cricket conversations and reply to cricket queries. Backed by our powerful in-house NLP algorithm that never lets you feel disconnected to the game.',
      link: 'chatbot-api',
    },
    performance: {
      name: 'Cricket Performance API',
      desc: 'APIs that offer a massive collection of statistical data enabling you to perform deep breakdown and analyses. Get interesting insights from player and team statistics, Player vs. Team Performance, Performance & Timeline of Players and Teams.',
      link: 'performance-api',
    },
  },
  football: {
    live_score_stats: {
      name: 'Live Score API & Statistics plans',
      desc: 'Innovate your app with rich, live and statistical data. Different plans to fulfill the need for a startup to Enterprise. Simple pricing, no contract, just signup and start building your ideas.',
    },
    enterprise: {
      name: 'Football Enterprise Plan',
      desc: 'Priority support and push notification for faster data delivery. You never run out server requests. Data archive on demand. Best suit for enterprise and high precision score updates. Free access to all Beta access. We ensure 99.9% API Uptime and fastest data delivery. And get that on SLA with Enterprise License.',
    },
    fantasy: {
      name: 'Football Fantasy API plans',
      desc: 'Ready-made APIs for building Cricket Fantasy App. A.I. powered Player Credit Value (Player Market Value) and User points calculation API.',
    },
    performance: {
      name: 'Football Performance API Plan',
      desc: 'Massive collection of Statistics API, the deeper breakdown of player and team statistics. Player vs. Team Performance. Performance & Timeline of Players and Teams.',
    },
  },
};
