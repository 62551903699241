<template>
  <div :class="['product-section', templateClass]" v-if="product">
    <div class="box-content">
      <div class="primary-info">
        <h2 v-if="product.name && (product.plan != 'enterprise')">{{product.name}}</h2>
        <div :class="['price-info', product.plan]">
          <span class="price-amount">
            {{formatCurrency(product.prices['1'].actual_price[currency.key], currency.key)}}
          </span><br/>
          <span class="duration">per month</span>
        </div>
        <p>
          <a class="buy-link"
          :href="'https://cricketapi.com/buy/?plans=1-' + product.key + '&currency=' + currency" title="buy">
            Buy
          </a>
        </p>
        <a class="know-more"
        :href="'https://cricketapi.com/plans/' + product.plan + '/'" :title="product.plan">
          Know more
        </a>
        <template v-if="planDesc">
          <div class="secondary-info-area"
            v-if="planDesc.push && planDesc.push.length">
            <h5>Push Access</h5>
            <ul class="match-access">
              <li v-for="(access_text, index) in planDesc.push"
              :key="index">
                {{access_text}}
              </li>
            </ul>
          </div>
          <div class="secondary-info-area"
            v-if="planDesc.priority_support && planDesc.priority_support.length">
            <h5>Priority Support</h5>
            <ul class="match-access">
              <li v-for="(access_text, index) in planDesc.priority_support"
              :key="index">
                {{access_text}}
              </li>
            </ul>
          </div>
          <div class="secondary-info-area"
            v-if="planDesc.beta && planDesc.beta.length">
            <h5>Beta</h5>
            <ul class="match-access">
              <li v-for="(access_text, index) in planDesc.beta"
              :key="index">
                {{access_text}}
              </li>
            </ul>
          </div>
          <div class="secondary-info-area"
            v-if="planDesc.server && planDesc.server.length">
            <h5>Server Access</h5>
            <ul class="match-access">
              <li v-for="(access_text, index) in planDesc.server"
              :key="index">
                {{access_text}}
              </li>
            </ul>
          </div>
        </template>
      </div>
      <div class="secondary-info">
        <div class="secondary-info-area">
          <h5>Free Requests</h5>
          <p>{{product.max_requests}} per month</p>
        </div>
        <template v-if="planDesc">
          <div class="secondary-info-area"
            v-if="planDesc.match && planDesc.match.length">
            <h5>Match Access</h5>
            <ul class="match-access">
              <li v-for="(access_text, index) in planDesc.match"
              :key="index">
                {{access_text}}
              </li>
            </ul>
          </div>
          <div class="secondary-info-area"
            v-if="planDesc.schedule && planDesc.schedule.length">
            <h5>Schedule Access</h5>
            <ul>
              <li v-for="(access_text, index) in planDesc.schedule"
              :key="index">
                {{access_text}}
              </li>
            </ul>
          </div>
          <div class="secondary-info-area" v-if="planDesc.coverage_detail">
            <h5>Coverage Detail</h5>
            <p>{{planDesc.coverage_detail}}</p>
          </div>
        </template>
      </div>
      <div class="secondary-info" v-if="planDesc">
        <div class="secondary-info-area" v-if="planDesc.api && planDesc.api.length">
          <h5>API Access</h5>
          <p v-if="typeof(planDesc.api) === 'string'">{{planDesc.api}}</p>
          <ul v-else class="api-access">
            <li v-for="(api_info, index) in planDesc.api"
            :key="index">
              {{api_info.name}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.product-section{
  --box-pad-top: #{size(30)};
  --box-pad-bottom: #{size(30)};
  --box-pad-left: #{size(16)};
  --box-pad-right: #{size(30)};

  --box-margin-top: #{size(32)};

  --box-title-font-size: #{size(16)};
  --box-title-font-line-height: #{size(20)};

  --box-para-font-size: #{size(16)};
  --box-para-font-line-height: #{size(24)};

  --price-info-margin: #{size(14)};

  --product-sub-section-pad-left: #{size(0)};

  @media screen and (min-width: $breakpoint-md) {
    --box-title-font-size: #{size(18)};
    --box-title-font-line-height: #{size(22)};
    --price-info-margin: #{size(24)};

    --product-sub-section-pad-left: #{size(36)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --box-pad-top: #{size(54)};
    --box-pad-bottom: #{size(43)};
    --box-pad-left: #{size(154)};
    --box-pad-right: #{size(54)};

    --product-sub-section-pad-left: #{size(66)};
  }

  margin-top: var(--box-margin-top);

  &.sub-section {
    padding-left: var(--product-sub-section-pad-left);
  }

  .box-content {
    padding: var(--box-pad-top)
      var(--box-pad-left)
      var(--box-pad-top)
      var(--box-pad-left);
    display: flex;
    flex-direction: column;
    background-color: var(--box-color-2);
  }

  .primary-info {
    max-width: size(310);
    h2 {
      font-family: var(--hero-font);
    }
  }

  .price-info {
    color: var(--secondary-text);
    margin: var(--price-info-margin) 0;
    .price-amount {
      font-size: var(--para-title-font-size);
      line-height: var(--para-title-line-height);
    }
    .duration {
      font-size: var(--schema-variable-font-size);
      line-height: var(--schema-variable-line-height);
    }
  }

  .buy-link {
    padding: size(8) size(24);
    background-color: var(--hero-text);
    border-radius: size(4);
    line-height: size(15);
    font-size: size(12);
    color: var(--master-text);
    display: inline-block;
    margin-bottom: size(7);
    &:hover {
      text-decoration: none;
      background-color: var(--secondary-text);
    }
  }

  .know-more {
    font-size: var(--schema-variable-font-size);
    line-height: var(--schema-variable-line-height);
    color: var(--secondary-text);
    text-decoration: underline;
  }

  .secondary-info-area{
    margin-top: size(15);
    h5 {
      font-size: var(--box-title-font-size);
      line-height: var(--box-title-font-line-height);
      font-family: var(--regular-font);
      font-weight: bold;
      margin-bottom: size(7);
      color: var(--hero-text);
    }
    p, ul >li {
      font-size: var(--box-para-font-size);
      line-height: var(--box-para-font-line-height);
      font-family: var(--regular-font);
    }
  }

  .secondary-info {
    .secondary-info-area{
      max-width: size(240);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    .price-info.enterprise {
      .price-amount {
        font-size: size(54);
        line-height: size(66);
      }
    }
    .box-content {
      >:not(.primary-info) {
        .secondary-info-area {
          &:not(:first-child) {
            margin-top: size(30);
          }
          ul.match-access >li {
            margin-bottom: size(14);
          }
        }
      }
    }
    .buy-link {
      margin-bottom: size(14);
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .box-content {
      flex-direction: row;

      >:not(.primary-info) {
        .secondary-info-area {
          padding-left: size(25);
        }
      }
    }
  }
}
</style>
<script>
import { CurrencyObjs } from '@/store/modules/page';

export default {
  props: {
    product: Object,
    currency: {
      type: Object,
      default: CurrencyObjs.EUR.value,
    },
    planDesc: Object,
    templateClass: String,
  },
};
</script>
