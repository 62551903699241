<template>
  <div :class="['hero', templateClass]">
    <div class="desc-area">
      <h1 v-if="page.title">{{ page.title }}</h1>
      <div class="hero-p" v-if="page.brief">
        <rz-markdown :text="page.brief"></rz-markdown>
      </div>
    </div>
    <div class="image-area" v-if="page.img">
      <rz-image-item :image="page.img" />
    </div>
  </div>
</template>
<style lang="scss">
.hero {
  --data-structure-hero-pad-bottom: #{size(60)};

  @media screen and (min-width: $breakpoint-md) {
    --data-structure-hero-pad-bottom: #{size(80)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --data-structure-hero-pad-bottom: #{size(100)};
  }

  .markdown {
    p, span.p {
      padding-top: size(16);
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
h1 {
  font-family: var(--hero-font);
  font-weight: bold;

  font-size: var(--section-title-font-size);
  line-height: var(--section-title-line-height);
  letter-spacing: 0.02em;
  color: var(--hero-text);
}

.hero {
  padding-left: var(--section-pad-left);
  padding-right: var(--junior-menu-pad-right);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &.docs-home, &.graphQL {
    h1 {
      font-size: size(60);
      line-height: size(80);
    }
  }
   &.data-structure {
    .desc-area {
      .hero-p {
        padding-bottom: var(--data-structure-hero-pad-bottom);
      }
    }
  }

  .desc-area {
    .hero-p {
      font-family: var(--hero-font);
      font-size: var(--para-hero-font-size);
      line-height: var(--para-hero-line-height);
      letter-spacing: 0.01em;
      max-width: var(--para-max-width);
      color: var(--hero-text);
    }
  }

  .image-area {
    min-height: var(--hero-img-height);
    @media screen and (min-width: $breakpoint-lg) {
      width: 40%;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .hero {
    flex-direction: row;
    &.docs-home, &.graphQL {
      padding-left: size(28);
      padding-right: size(28);
      flex-direction: column;
      h1 {
        font-size: size(100);
        line-height: size(120);
      }
    }
    &.data-structure {
      .desc-area {
        .hero-p {
          padding-bottom: size(80);
        }
      }
    }
    .image-area {
      margin-top: #{size(32)};
    }
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .hero {
    flex-direction: row;
    &.docs-home, &.graphQL {
      flex-direction: row;
      padding-left: size(80);
      padding-right: size(80);
    }
    &.docs-home , &.graphQL {
      h1 {
        font-size: size(214);
        line-height: size(285);
      }
    }
    .desc-area {
      width: 60%;
      .hero-p {
        padding-bottom: size(60);
      }
    }

    .image-area {
      min-width: size(335);
    }
    &.data-structure {
      .desc-area {
        .hero-p {
          padding-bottom: size(100);
        }
      }
    }
  }
}
</style>
<script>
export default {
  props: {
    page: Object,
    templateClass: String,
  },
};
</script>
