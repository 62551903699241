<template>
<!-- eslint-disable max-len -->
  <button class="preview-button" @click="$emit('click')" aria-label="Preview">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#FFDB14"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
       d="M21.2222 10.5556C22.2041 10.5556 23 9.75962 23 8.77778C23 7.79594 22.2041 7 21.2222 7C20.2404 7 19.4444 7.79594 19.4444 8.77778C19.4444 9.75962 20.2404 10.5556 21.2222 10.5556ZM22.2381 8.77778C22.2381 9.33883 21.7833 9.79365 21.2222 9.79365C20.6612 9.79365 20.2064 9.33883 20.2064 8.77778C20.2064 8.21673 20.6612 7.7619 21.2222 7.7619C21.7833 7.7619 22.2381 8.21673 22.2381 8.77778Z" fill="#0053D6"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
      d="M19.4623 7.7619H10.0476V10.8095H7V23H19.1905V19.9524H22.2381V10.5377C22.0087 10.6704 21.751 10.7595 21.4762 10.7938V19.1905H10.8095V8.52381H19.2062C19.2405 8.24903 19.3296 7.99129 19.4623 7.7619ZM7.7619 11.5714H10.0476V19.9524H18.4286V22.2381H7.7619V11.5714Z" fill="#0053D6"/>
    </svg>
    <span>{{text}}</span>
  </button>
</template>
<style lang="scss">
.preview-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: size(10);
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: color 0.3s linear , content 0.3s linear;
  color: var(--hero-text);

  svg, span {
    display: block;
  }

  span {
    text-align: left;
    font-size: size(12);
    padding: size(8) 0 size(8) size(8)
  }

  &:hover {
    color: var(--secondary-text);
    svg{
      circle {
        fill: #0053D6;
      }
      path {
        fill: #fff;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: size(12);

    span {
      padding: 0 0 0 size(16)
    }
  }
}
</style>
<script>
export default {
  props: {
    text: String,
  },
};
</script>
