<template>
  <div>
    <span class="status-reason">{{status.reason}}
      <img  @click="expand = !expand"
        :class="[!expand ? 'desc-expand-icon': 'inv-icon']"
        alt="Expand"
        v-if="status.desc" src="@/assets/http-desc-expand.svg" />
    </span>
    <p class="desc" v-if="status.desc && expand"> {{status.desc}}</p>
    <div class="links" v-if="status.links && status.links.length">
      <rz-link-item
        v-for="(link, i) in status.links"
        :key="i"
        :link="link"
        linkClass="link-secondary" />
    </div>
    <div
      class="response-items"
      v-if="status.responses_keys && status.responses_keys.length">
      <div
        v-for="(rk, i) in status.responses_keys"
        :key="i"
      class="response-item"
      >
      <div v-if="!responseState.status.isLoaded">...</div>
      <div v-else>
        <PreviewButton @click="$emit('showResponse', page.responses[rk])" :text="page.responses[rk].name" />
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewButton from '@/components/PreviewButton.vue';

export default {
  components: { PreviewButton },
  data() {
    return {
      expand: false,
    };
  },
  props: {
    status: Object,
    responseState: Object,
    page: Object,
  },
};
</script>
