<template>
  <div class="page-docs-api graphql-docs" v-if="page">
    <div class="loader" v-if="!pageState.status.isLoaded">
      <LoadingPlaceholder :state="pageState" :placeholderStyleNumber="placeholderStyleNumber"/>
    </div>
    <div class="content" v-else>
      <GotoTop />
      <JuniorMenu :links="page.toc"/>
      <div class="hero">
        <h1 class="hide-on-mobile">{{ page.title }}</h1>
        <div
          @click="onSeniorMenuClick"
          class="show-on-mobile">
          <div class="hero-title-block">
            <h1>{{ page.title }}</h1>
            <div class="icon-box">
              <DropdownIcon
                :rootClass="'color-secondary'"
                :state="seniorMenuState.animationState"
                @updateState="(state) => {updateLinkAnimationState(state)}"
                @iconClick="(state) => {onSubMenuIconClick(state)}"
              />
            </div>
          </div>
        </div>
        <div class="desc-area">
          <div class="hero-p">
            <rz-markdown  v-if="page.brief" :text="page.brief"></rz-markdown>
          </div>
          <div class="image-area" v-if="page.img">
            <rz-image-item :image="page.img" />
          </div>
        </div>
      </div>

      <template v-for="detail in page.field_order">

        <section v-if="detail === 'getting_started' && page.getting_started" :key="detail.key"
          class="getting-started hover-element" id="goto-getting-started">
          <svg><use v-bind="{'xlink:href':'#info-icon'}"></use></svg>
          <rz-markdown v-if="page.getting_started.desc" :text="page.getting_started.desc" />
        </section>

        <!-- <section v-if="detail === 'explores' && page.explores" :key="detail.key" id="goto-explores"
          class="explores hover-element" >
          <h2>{{page.explores_name}}</h2>
          <button v-if="page.explores_name" class="url-btn"
            @click="copyUrl('goto-explores')"><img src="@/assets/link.svg" /></button>
          <p>{{page.explores_desc}}</p>
          <template v-for="content in page.explores">
            <h2 :key="content.key">{{content.name}}</h2>
            <button v-if="page.explores" class="url-btn"
              @click="copyUrl('goto-explores')" :key="content.key"><img src="@/assets/link.svg" /></button>
            <rz-markdown v-if="content.desc" :text="content.desc" :key="content.key" />
            <template v-for="link in content.links">
              <router-link
                class="btn focus"
                :key="link.key"
                v-if="link.route_params.key"
                :to="{
                  name: link.route_params.key,
                  params: {
                    page: link.route_params.page
                  }
                }"
                :exact-active-class="'active'">
                {{link.name}}
              </router-link>
              <a
                class="btn focus"
                :key="link.key"
                :href="link.url"
                v-else-if="link.url">
                {{link.name}}
              </a>
            </template>
            <img v-if="content.img" :src="content.img.url" :key="content.key" />
          </template>
        </section> -->

        <section v-if="detail === 'more_scenarios'
          && page.graphql_sample_queries && page.graphql_sample_queries.length > 1" :key="detail.key"
          class="sample-code hover-element more-scenarios"
          :id="`goto-more-scenarios`">
          <h2 v-if="page.route_value === 'authentication-query'">Access & Limitations</h2>
          <h2 v-else>{{detail.split('_').join(' ')}}</h2>
          <button  class="url-btn" @click="copyUrl(`goto-more-scenarios`)">
            <img src="@/assets/link.svg" />
          </button>
          <p v-if="page.more_scenarios_desc">{{page.more_scenarios_desc}}</p>
          <div class="scenarios-wrapper">
            <template v-for="(code, i) in page.graphql_sample_queries">
              <div class="box" :key="i" v-if="i >= 1">
                <div class="code-group">
                  <CodeDisplay :code="code" :moreScenarios="true"/>
                </div>
                <template v-for="query in code.queries">
                  <div class="api-data-desc" :key="query.key" v-if="query.sample_response_key">
                    <div>
                      <PreviewButton @click="showResponse(page.responses[query.sample_response_key])"
                        :text="page.responses[query.sample_response_key]
                        && page.responses[query.sample_response_key].name" />
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </section>

        <section v-if="detail === 'query_inputs' && page.query_inputs && page.query_inputs.length > 0"
          :key="detail.key" id="goto-query-inputs"
          class="query-inputs hover-element bg-2">
          <h2>Query Arguments</h2>
          <button class="url-btn" @click="copyUrl('goto-query-inputs')"><img src="@/assets/link.svg" /></button>
          <p v-if="page.query_inputs_desc">{{page.query_inputs_desc}}</p>
          <div class="box">
            <div class="box-content">
              <GraphqlQueryInput :query="page.query_inputs" />
            </div>
          </div>
        </section>

        <section v-if="detail === 'sample_queries' && page.graphql_sample_queries
          && page.graphql_sample_queries.length > 0"
          :key="detail.key" class="sample-code hover-element"
          :id="`goto-sample-code`">
          <h2>{{page.sample_query_title}}</h2>
          <button  class="url-btn" @click="copyUrl(`goto-sample-code`)">
            <img src="@/assets/link.svg" /></button>
          <p>{{page.sample_query_desc}}</p>
          <template v-for="(code, i) in page.graphql_sample_queries">
            <template v-for="query in code.queries">
              <div class="box" :key="query.key" v-if="i < 1">
                <div class="code-group" v-if="page.responses && page.responses[query.sample_response_key]">
                  <CodeDisplay :code="query"
                    :responseKey="query.sample_response_key"/>
                </div>
                <div class="api-data-desc" v-if="query.sample_response_key">
                  <div>
                    <PreviewButton @click="showResponse(page.responses[query.sample_response_key])"
                      :text="page.responses[query.sample_response_key]
                      && page.responses[query.sample_response_key].name" />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </section>

        <section v-if="detail === 'http_status' && page.status_codes"
          id="goto-http-status"
          class="http-status bg-2 hover-element" :key="detail.key">
          <h2>HTTP Extensions</h2>
          <button  class="url-btn" @click="copyUrl('goto-http-status')"><img src="@/assets/link.svg" /></button>
          <p v-if="page.http_desc"><rz-markdown :text="page.http_desc" /></p>
          <div class="box">
            <div class="box-content">
              <HTTPStatusSection
                @showResponse="showResponse"
                :page="page"
                :responseState="pageResponsesState"
              />
            </div>
          </div>
        </section>

        <section
          :key="detail.key"
          id="goto-downloads"
          v-if="detail === 'downloads' && page.downloads"
          class="downloads  bg-2 hover-element" >
          <h2 v-if="page.downloads_title">{{page.downloads_title}}</h2>
          <button class="url-btn" @click="copyUrl('goto-downloads')"><img src="@/assets/link.svg" /></button>
          <p v-if="page.downloads_desc"><rz-markdown :text="page.downloads_desc" /></p>
          <div class="box">
            <div class="box-content">
              <div class="downloads-wrapper">
                <div v-for="responseKey in page.downloads && page.downloads[0]
                  && page.downloads[0].responses_keys" :key="responseKey.key" class="wrapper">
                  <PreviewButton
                    @click="showResponse(page.responses[responseKey])"
                    :text="page.responses[responseKey]
                    && page.responses[responseKey].name" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>

      <div
       v-if="page.hasMoreDeliveryMethods"
       class="page-tabs">
        <ul>
          <li
          v-for="(dm, i) in page.supported_delivery_methods"
          :key="i"
          :class="{active: dm.method.is(page.delivery_method)}"
          >
            <rz-link-item :link="dm.link" />
          </li>
        </ul>
      </div>

      <section class="pull-left page-support-section">
        <SupportSection />
      </section>


      <div v-if="isCopied" class="toaster-msg">
        <span>Link Copied</span>
      </div>

      <SlideInPopup
        v-if="activePreviewSample || activePreviewObject"
        :title="activePreviewTitle"
        :desc="activePreviewDesc"
        :downloadUrl="activePreviewDownloadUrl"
        :downloadFilename="activePreviewDownloadFilename"
        :keepParentFocus="false"
        :keepParentScroll="false"
        :sidebarLinks="page.downloads ? page.downloads : null"
        :pageResponse="page"
        :graphql="true"
        :activeLink="activePreviewSample ? activePreviewSample.response_key : null"
        :showSidebar="page.responses && Object.keys(page.responses).length > 0"
        @shouldClose="slideInShouldClose()"
        @downloadClick="slideInAsksDownload()"
        @showResponse="showResponse"
        >
        <template v-if="activePreviewSample">
          <div class="loader" v-if="!activePreviewSampleState.status.isLoaded">
            <rz-request-state :state="activePreviewSampleState" />
          </div>
          <div v-else class="popup-wrapper">
            <template v-for="code in page.graphql_sample_queries" >
              <template v-for="info in code.queries">
                <div class="code-group" :key="info.key"  v-if="responseKey && responseKey === info.sample_response_key">
                  <CodeDisplay :code="info" :isPopup="true" />
                </div>
              </template>
            </template>
            <div class="graphql-preview-wrapper">
              <div class="inner-wrapper">
                <json-preview :src="activePreviewObject" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <DataclassDisplay
            v-if="activePreviewObject.typ === 'dataclass'"
            @showSchemaDefinition="showSchemaDefinition"
            :dataclass="activePreviewObject"
            :defs="schema.defs" />
          <EnumDisplay
            v-else-if="activePreviewObject.typ === 'enum'"
            :enumDef="activePreviewObject"
            :defs="schema.defs" />
        </template>
      </SlideInPopup>
    </div>
  </div>
</template>
<script>
import '@/assets/styles/views/docs_api.scss';
import Config from '@/config';

import { RequestState, RequestStateStatus } from '@/api/core';
import { APISchema } from '@/store/modules/schema';
import { mapState } from 'vuex';
import { SmartMorphAnimationState } from '@/core/view/smartMorph';

import CodeDisplay from '@/components/CodeDisplay/GraphqlCodeDisplay.vue';
import HTTPStatusSection from '@/components/API/GraphqlHttpSection.vue';
import GraphqlQueryInput from '@/components/API/GraphqlQueryInput.vue';
import SupportSection from '@/components/SupportSection.vue';
import SlideInPopup from '@/components/API/GraphqlPopup.vue';
import JuniorMenu from '@/components/JuniorMenu.vue';
import GotoTop from '@/components/GotoTop.vue';
import JSONPreview from '@/components/CodeDisplay/GraphqlResponse.vue';
import DataclassDisplay from '@/components/CodeDisplay/DataclassDisplay.vue';
import EnumDisplay from '@/components/CodeDisplay/EnumDisplay.vue';
import DropdownIcon from '@/components/InteractiveIcons/DropdownIcon.vue';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';
import PreviewButton from '@/components/PreviewButton.vue';

export default {
  components: {
    GraphqlQueryInput,
    CodeDisplay,
    HTTPStatusSection,
    SupportSection,
    SlideInPopup,
    JuniorMenu,
    GotoTop,
    DataclassDisplay,
    EnumDisplay,
    DropdownIcon,
    PreviewButton,
    'json-preview': JSONPreview,
    LoadingPlaceholder,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      seniorMenuState: (state) => state.link.seniorMenuState,
    }),
  },

  data() {
    return {
      schema: null,
      consoleLink: Config.consoleHost,
      schemaState: new RequestState(),
      pageResponsesState: new RequestState(),

      activePreviewSample: null,
      activePreviewSampleState: new RequestState(),

      activePreviewDownloadUrl: null,
      activePreviewDownloadFilename: null,
      activePreviewTitle: null,
      activePreviewDesc: null,
      activePreviewObject: null,
      placeholderStyleNumber: 0,
      isCopied: false,
      responseKey: null,
    };
  },

  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
        || to.params.sport !== from.params.sport
        || to.params.page !== from.params.page
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (this.dataLoaded) {
      console.log('Mounted page', this.page.route_value, this.page.sports);
      let sameRoute = this.page.route_value === this.$route.params.page;
      sameRoute = sameRoute && this.page.sports === this.$route.params.sport;

      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
      this.scrollToElement();
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else if (!this.schema) {
      // This case comes when, comp reloaded
      // but data already comes from Vuex
      this.fetchClientSide();
    }
  },

  methods: {
    fetchData() {
      let sportValue = this.$route.params.sport;
      if ((!sportValue) && this.$route.meta && this.$route.meta.sportValue) {
        sportValue = this.$route.meta.sportValue;
      }
      let route;
      const routeValue = this.$route.params.page;
      // Added this for testing purpose

      if (routeValue === 'test-query') {
        route = `/${sportValue}/graphql/docs`;
      } else {
        route = `/${sportValue}/graphql/docs/`;
      }
      return this.$store.dispatch('page/fetchGraphqlActiveAPI', { route, routeValue }).catch((err) => {
        console.error('Error on fetch Active Page', err);
      });
    },

    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
      // For changing placeholder design
      this.placeholderStyleNumber = Math.floor(Math.random() * 2);
    },

    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchClientSide();
      });
    },

    fetchClientSide() {
      if (!this.dataLoaded) {
        console.log('Page not loaded');
        return Promise.reject();
      }

      this.pageResponsesState.status = RequestStateStatus.Loading;
      return this.fetchSchema().then(() => {
        return this.fetchPageResponses();
      });
    },

    fetchSchema() {
      this.schemaState.status = RequestStateStatus.Loading;
      return APISchema.fetch(this.$apiInstance, this.page.endpoint_key).then((schema) => {
        this.schema = schema;
        this.schemaState.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        console.error(err);
        this.schemaState.status = RequestStateStatus.Error;
        this.schemaState.ref = err;
      });
    },

    fetchPageResponses() {
      this.pageResponsesState.status = RequestStateStatus.Loading;
      return this.page.fetchResponses(this.$apiInstance, this.$store).then(() => {
        this.pageResponsesState.status = RequestStateStatus.Loaded;
        this.scrollToElement();
      }).catch((err) => {
        console.error(err);
        this.pageResponsesState.status = RequestStateStatus.Error;
        this.pageResponsesState.ref = err;
      });
    },

    showSchemaDefinition(defs, path) {
      this.activePreviewTitle = defs.name;
      this.activePreviewDesc = path;
      this.activePreviewObject = defs;
    },

    showResponse(response) {
      this.responseKey = response.response_key;
      this.activePreviewTitle = response.name;
      this.activePreviewDesc = response.desc;
      this.activePreviewDownloadUrl = response.downloadUrl();
      this.activePreviewSample = response;

      this.activePreviewSampleState.status = RequestStateStatus.Loading;
      response.fetchFile(this.$apiInstance).then((resp) => {
        this.activePreviewObject = resp.response;
        this.activePreviewSampleState.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        console.error(err);
        this.activePreviewSampleState.status = RequestStateStatus.Error;
        this.activePreviewSampleState.ref = err;
      });
    },

    onJSONPreviewLevelFound() {
    },

    slideInShouldClose() {
      this.activePreviewTitle = null;
      this.activePreviewDesc = null;
      this.activePreviewSample = null;
      this.activePreviewObject = null;
      this.activePreviewDownloadUrl = null;
    },

    slideInAsksDownload() {
    },

    onSeniorMenuIconClick(state) {
      if (!state.isEnd) {
        this.closeSeniorMenu();
      } else {
        this.openSeniorMenu();
      }
    },

    onSeniorMenuClick() {
      if (this.seniorMenuState.expanded) {
        this.$store.commit('updateDailogActive', { active: false });
        this.updateSeniorLink({ animationState: SmartMorphAnimationState.Init });
        this.closeSeniorMenu();
      } else {
        this.$store.commit('updateDailogActive', { active: true });
        this.updateSeniorLink({ animationState: SmartMorphAnimationState.End });
        this.openSeniorMenu();
      }
    },

    openSeniorMenu() {
      this.updateSeniorLink({ beginCollapse: false, expanded: true });
    },

    closeSeniorMenu() {
      this.updateSeniorLink({ beginCollapse: true });

      // Wait for animation to finish
      setTimeout(() => {
        this.updateSeniorLink({ beginCollapse: false, expanded: false });
      }, 455);
    },

    updateLinkAnimationState(animationState) {
      this.updateSeniorLink({ animationState });
    },

    updateSeniorLink(newState) {
      this.$store.commit('link/updateSeniorMenuState', newState);
    },
    copyUrl(target) {
      const element = document.createElement('textarea');
      element.value = `${window.location.href}#${target}`;
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    },
    scrollToElement() {
      const url = window.location.href;
      if (url.includes('#')) {
        const sectionId = url.split('#')[1];
        const scroll = document.getElementById(sectionId);
        scroll.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>
<style lang="scss">
.graphql-docs {
  .loader {
    padding-top: size(6);
    color: #e6e4e4;
  }
  section {
    .api-sample-code {
      color: var(--secondary-text);
      .page-tabs {
        a {
          cursor: pointer;
        }
      }
    }
    &.downloads {
      .downloads-wrapper {
        .wrapper {
          margin-right: size(20);
          margin-bottom: size(15);
          @media screen and (min-width: $breakpoint-lg) {
            margin-right: size(30);
            margin-bottom: size(20);
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
    & > .markdown {
      .p {
        padding-top: 0;
        padding-right: 0;
      }
    }
    .markdown {
      .p {
        padding-top: var(--section-para-top-top);
        padding-right: var(--docs-page-pad-secondary-right);
      }
      img {
        max-height: size(180);
        padding-top: size(20);
        @media screen and (min-width: $breakpoint-md) {
          max-height: size(285);
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-height: size(515);
        }

      }
    }
    & > img {
      display: block;
      max-height: size(180);
      padding-top: size(20);
      @media screen and (min-width: $breakpoint-md) {
        max-height: size(285);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-height: size(515);
      }

    }
    h2 {
      text-transform: capitalize;
    }
    &.getting-started, &.explores {
      & > a, .link-item {
        margin-top: size(15);
        background: var(--hero-text);
        border-color: var(--hero-text);
        color: var(--regular-text-inv);
      }
    }
    &.getting-started {
      padding: size(15);
      margin-top: var(--section-pad-top);
      background: var(--master-bg-lite);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(20);
      }
      svg {
        width: size(28);
        height: size(28);
        fill: var(--secondary-text);
        vertical-align: top;
        margin-right: size(5);
      }
      .markdown {
        display: inline-block;
        vertical-align: top;
        width: 88%;
        .p {
          font-size: size(16);
          line-height: size(24);
          text-align: left;
          width: 100%;
          padding: 0;
          a {
            font-size: size(16);
            line-height: size(24);
          }
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(18);
            line-height: size(26);
            a {
              font-size: size(18);
              line-height: size(26);
            }
          }
        }
      }
    }
    &.sample-code {
      .box {
        padding-left: 0;
        padding-top: size(30);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(50);
          padding-left: 0
        }
        .box-content {
          border: none;
          background: var(--box-color-2);
        }
      }
    }
    &.more-scenarios {
      .scenarios-wrapper {
        background-color: var(--box-color-1);
        padding: var(--section-box-content-pad-top)
        0 var(--section-box-content-pad-bottom) var(--section-box-content-pad-left);
        margin-top: var(--section-box-pad-top);
        margin-left: var(--section-box-pad-left);
      }
      .api-data-desc {
        margin-top: 0;
        padding-top: size(15);
        padding-left: var(--level-3-content-left);
        @media screen and (min-width: $breakpoint-md) {
          padding-top: var(--level-3-content-top);
          margin-top: 0;
        }
      }
      .box {
        padding: 0 0 size(20);
        border-bottom: size(1) solid var(--regular-text-inv);
        margin: size(27) var(--docs-page-pad-secondary-right) 0 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
  .api-data-desc {
    margin-top: size(15);
    .response-items {
      .response-item {
        &:empty {
          padding: 0;
        }
      }
    }
  }
  .popup-wrapper {
    display: flex;
    height: 100%;
    .code {
      border-radius: 0;
    }
    .code-group {
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        width: 50%;
        display: block;
      }
      pre {
        height: 100%;
      }
    }
    .graphql-preview-wrapper {
      background: #242d50;
      width: 100%;
      @media screen and (min-width: $breakpoint-lg) {
        width: 50%;
      }
      .inner-wrapper {
        background: #242d50;
        padding: size(25);
      }
    }
  }
}
</style>
