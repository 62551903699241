<template>
  <div class="sample-code-group">
    <div
      class="sample-code-group-item"
      v-for="(code, i) in codeGroup.codes"
      :key="i"
    >
      <CodeDisplay :code="code"  :showTitle="codeGroup.codes.length > 1"/>
    </div>
  </div>
</template>
<script>
import CodeDisplay from '@/components/CodeDisplay/CodeDisplay.vue';

export default {
  components: { CodeDisplay },
  props: {
    codeGroup: Object,
  },
};
</script>
<style lang="scss">
.sample-code-group-item {
  border-bottom: size(1) solid var(--regular-text-inv);
  &:last-child {
    border-bottom: 0;
  }
}
</style>
