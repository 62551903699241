<template>
  <div class="web-page docs" v-if="page">
    <div class="loader" v-if="!pageState.status.isLoaded">
      <LoadingPlaceholder :state="pageState" />
    </div>
    <div class="content" v-else>
      <GotoTop />
      <div
        @click="onSeniorMenuClick"
        class="show-on-mobile">
        <div class="hero-title-block">
          <h1>{{ page.title }}</h1>
          <div class="icon-box">
            <DropdownIcon
              :rootClass="'color-secondary'"
              :state="seniorMenuState.animationState"
              @updateState="(state) => {updateLinkAnimationState(state)}"
              @iconClick="(state) => {onSubMenuIconClick(state)}"
            />
          </div>
        </div>
      </div>
      <HeroBanner :page="page" :templateClass="page.route_value" />
      <div class="data-wrapper" v-if="page.route_value === 'data-structure'">
        <DataStructure
          v-for="(section, i) in page.sections"
          :key="i"
          :index="i"
          :pageSection="section.detail"
          :class="[page.route_value ,page.route_value +'-' + i]"
        >
        </DataStructure>
        <section class="pull-left page-support-section">
          <SupportSection />
        </section>
      </div>
      <template
        v-else
        >
        <template v-for="(section, i) in page.sections">
          <PageSection
            :key="i"
            :id="`goto-section-${i}`"
            :section="section"
          >
          </PageSection>
        </template>
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.web-page {
  .support-section {
    h2 {
      font-family: var(--hero-font);
    }
  }
  &.docs {
    .hero {
      h1 {
        padding: size(20) 0;
        line-height: var(--title-line-height);
        @media screen and (min-width: $breakpoint-md) {
          padding-top: 0;
          padding-bottom: var(--title-pad-bottom);
          line-height: var(--section-title-line-height);
        }
      }
      &.docs-home , &.graphQL {
        h1 {
          @media screen and (min-width: $breakpoint-md) {
            line-height: size(285);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.web-page{
  --web-page-pad-top: #{size(20)};
  --web-page-pad-right: #{size(16)};
  --web-page-pad-bottom: #{size(20)};
  --web-page-pad-left: #{size(0)};
  --web-page-pad-secondary-right: #{size(16)};
  --support-section-top-pad: #{size(27)};

  @media screen and (min-width: $breakpoint-md) {
    --web-page-pad-top: #{size(30)};
    --web-page-pad-right: #{size(24)};
    --web-page-pad-bottom: #{size(32)};
    --web-page-pad-left: #{size(28)};
    --web-page-pad-secondary-right: #{size(24)};
    --support-section-top-pad: #{size(60)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --web-page-pad-top: #{size(54)};
    --web-page-pad-right: #{size(55)};
    --web-page-pad-bottom: #{size(52)};
    --web-page-pad-left: #{size(46)};
    --web-page-pad-secondary-right: #{size(150)};
    --support-section-top-pad: #{size(100)};
  }

  padding: var(--docs-page-pad-top) 0 var(--docs-page-pad-bottom) 0;

  .data-wrapper {
    display: flex;
    flex-wrap: wrap;
    .page-support-section {
      width: 100%;
      margin-top: 0;
    }
  }

  .show-on-mobile {
    position: fixed;
    top: size(36);
    left: 0;
    right: 0;
    padding: size(20) size(16) 0;
    background: var(--regular-text-inv);
    z-index: 100;
    .hero-title-block {
      margin-bottom: 0;
    }
  }

  .hero-title-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-line);
    margin-bottom: size(20);

    cursor: pointer;

    h1 {
      padding-bottom: size(13);
      font-family: var(--regular-font);
      font-weight: 400;
      font-size: var(--title-font-size);
      line-height: var(--title-line-height);
      letter-spacing: var(--title-letter-space);
      color: var(--hero-text);
    }

    .icon-box {
      margin-top: -2px; //🤷‍♀️
      margin-right: 2px; //🤷‍♀️
      .svg-icon {
        width: size(10);
        height: size(10);
      }
    }
  }

}
</style>
<script>
import { mapState } from 'vuex';
import { SmartMorphAnimationState } from '@/core/view/smartMorph';

import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';
import PageSection from '@/components/WebPage/PageSection.vue';
import HeroBanner from '@/components/WebPage/HeroBanner.vue';
import GotoTop from '@/components/GotoTop.vue';
import DataStructure from '@/components/WebPage/DataStructure.vue';
import SupportSection from '@/components/SupportSection.vue';
import DropdownIcon from '@/components/InteractiveIcons/DropdownIcon.vue';

export default {
  components: {
    HeroBanner,
    PageSection,
    GotoTop,
    LoadingPlaceholder,
    DataStructure,
    SupportSection,
    DropdownIcon,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      seniorMenuState: (state) => state.link.seniorMenuState,
    }),
  },

  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
        || to.params.sport !== from.params.sport
        || to.params.page !== from.params.page
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (this.dataLoaded) {
      console.log('Mounted page', this.page.route_value, this.page.sports);
      let sameRoute = this.page.route_value === this.$route.params.page;
      sameRoute = sameRoute && this.page.sports === this.$route.params.sport;

      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.fetchData();
    }
  },

  methods: {
    fetchData() {
      let routeSuffix = 'docs/guides/';
      let sportValue = this.$route.params.sport;
      if ((!sportValue) && this.$route.meta && this.$route.meta.sportValue) {
        sportValue = this.$route.meta.sportValue;
      }
      if (this.$route.name === 'docsPage') {
        routeSuffix = 'pages/';
      }
      const route = `/${sportValue}/${routeSuffix}`;
      let routeValue = this.$route.params.page;
      if ((!routeValue) && this.$route.meta && this.$route.meta.routeValue) {
        routeValue = this.$route.meta.routeValue;
      }
      return this.$store.dispatch('page/fetchActivePage', { route, routeValue }).catch((err) => {
        console.error('Error on fetch Active Page', err);
      });
    },

    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.fetchData();
    },

    updateSeniorLink(newState) {
      this.$store.commit('link/updateSeniorMenuState', newState);
    },

    onSeniorMenuIconClick(state) {
      if (!state.isEnd) {
        this.closeSeniorMenu();
      } else {
        this.openSeniorMenu();
      }
    },

    onSeniorMenuClick() {
      if (this.seniorMenuState.expanded) {
        this.$store.commit('updateDailogActive', { active: false });
        this.updateSeniorLink({ animationState: SmartMorphAnimationState.Init });
        this.closeSeniorMenu();
      } else {
        this.$store.commit('updateDailogActive', { active: true });
        this.updateSeniorLink({ animationState: SmartMorphAnimationState.End });
        this.openSeniorMenu();
      }
    },

    openSeniorMenu() {
      this.updateSeniorLink({ beginCollapse: false, expanded: true });
    },

    closeSeniorMenu() {
      this.updateSeniorLink({ beginCollapse: true });

      // Wait for animation to finish
      setTimeout(() => {
        this.updateSeniorLink({ beginCollapse: false, expanded: false });
      }, 455);
    },

    updateLinkAnimationState(animationState) {
      this.updateSeniorLink({ animationState });
    },

  },
};
</script>
