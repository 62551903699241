<template>
  <div class="master-menu" :class="{'master-menu-expanded': expandMenu}">
    <div class="logo-box">
      <a href="https://www.cricketapi.com">
        <LogoImage class="logo" v-if="menuBehaviour && !expandMenu"></LogoImage>
        <img class="logo" v-else src="@/assets/rca-logo-white.svg" alt="Cricket API Logo" />
      </a>
    </div>
    <div class="nav-box" :class="{expanded: expandMenu, 'begin-collapse': beginCollapse}">
      <MasterMenuNavMain
        :menuItems="menuItems"
        :activeLink="activeLink"
        :animState.sync="moreActionState"
        :showDownArrow="true"
      />
      <div class="actions">
        <div class="items">
          <router-link
            class="btn focus"
            :to="{name: 'docsGuide',params: {page: 'first-step'}}"
          >Get Started</router-link>
          <ConsoleButton />
        </div>
      </div>
    </div>
    <div class="more-action-box">
      <button class="action-button" aria-label="Burger Menu">
        <BurgerMenuIcon :state.sync="moreActionState" />
      </button>
    </div>
  </div>
</template>

<style lang="scss">

body.disable-scroll {
  overflow-y: hidden;
}

.master-menu {
  // Ref https://sass-lang.com/documentation/breaking-changes/css-vars
  --logo-height: #{size(28)};
  --logo-left-pad: #{size(16)};
  --logo-box-width: auto;
  --more-action-box-width: #{size(52)};
  --more-action-button-height: #{size(20)};

  @media screen and (min-width: $breakpoint-md) {
    --logo-height: #{size(42)};
    --logo-left-pad: #{size(20)};
    --more-action-box-width: #{size(72)};
    --more-action-button-height: #{size(29)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --logo-height: #{size(45)};
    --logo-left-pad: #{size(24)};
    --logo-box-width: #{size(250-16)};
  }

  display: flex;
  background: var(--master-bg);
  height: var(--master-menu-container-height);
  justify-content: space-between;

  position: fixed;
  z-index: var(--master-menu-z-index);
  top: 0;
  left: 0;
  right: 0;
  transition: background-color var(--master-menu-transition-duration);

  &.overlay-menu:not(.master-menu-expanded) {
    // transform: translate3d(0, -100%, 0);
    background-color: var(--master-bg-lite);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  .logo-box {
    padding-left: var(--logo-left-pad);
    padding-top: #{size(3)};
    @include flex-center;
    justify-content: left;

    .logo {
      height: var(--logo-height);
    }
  }

  .nav-box {
    flex: auto;

    background: var(--master-bg);
    position: fixed;
    top: var(--master-menu-container-height);
    left: 0;
    right: 0;
    bottom: 0;

    flex-direction: column;
    justify-content: space-between;
    display: none;
    z-index: var(--nav-box-z-index);
    &.expanded {
      display: flex;
    }

    animation: drop-menu 350ms ease-out;

    &.begin-collapse {
      animation: drop-menu-rev 450ms ease-out forwards;
    }

    .actions {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: size(20);

      // Indicator for .main overflow scroll
      box-shadow: 0px -2px 20px 5px var(--master-bg);

      .items {
        margin: auto;
      }
      .btn {
        display: block;
        margin-bottom: size(12);
        min-width: size(60);
        &:hover {
          background: var(--hero-text);
          color: var(--regular-text-inv);
          border-color: var(--hero-text);
        }
      }
    }
  }
  &.overlay-menu {
    .action-button {
      .svg-icon {
        .default-fill {
          fill: var(--secondary-text);
        }
      }
    }
    &.master-menu-expanded {
      .svg-icon {
        .default-fill {
          fill: var(--master-highlight);
        }
      }
    }
  }

  .more-action-box {
    flex: 0 0 var(--more-action-box-width);
    @include flex-center;

    .action-button {
      height: var(--more-action-button-height);
    }
  }

  #cricket_docs_link {
    display: none;
  }

  @media screen and (min-width: $breakpoint-md) {
    .nav-box {
      .actions {
        margin-bottom: size(28);
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    justify-content: initial;
    .logo-box {
      flex: 0 0 var(--logo-box-width);
    }

    .nav-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: initial;
      background: none;
      animation: none;

      &.begin-collapse {
        animation: none;
      }

      .actions {
        margin-right: var(--junior-menu-pad-right);
        justify-content: center;
        margin-bottom: 0;
        box-shadow: none;

        .items {
          // margin: auto 0;
          display: flex;
          flex-direction: row-reverse;
        }

        .btn {
          margin-left: size(27);
          margin-bottom: 0;
          padding: 0 size(14);
          line-height: size(26);
        }
      }
    }

    .more-action-box {
      display: none;
    }
    &.overlay-menu {
      .nav-box {
        background: none;
        .actions {
          .btn {
            color: var(--secondary-text);
            &:hover {
              color: var(--regular-text-inv);
            }
            &.focus {
              &:hover {
                color: var(--regular-text-inv);
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { SmartMorphAnimationState } from '@/core/view/smartMorph';
import BurgerMenuIcon from '@/components/InteractiveIcons/BurgerMenuIcon.vue';
import ConsoleButton from '@/components/Utils/ConsoleButton.vue';
import MasterMenuNavMain from './MasterMenuNavMain.vue';
import LogoImage from './LogoImage.vue';

export default {
  components: {
    BurgerMenuIcon, MasterMenuNavMain, LogoImage, ConsoleButton,
  },
  props: {
    menuItems: {
      type: Array,
    },
    activeLink: String,
    menuBehaviour: Boolean,
  },
  data() {
    return {
      beginCollapse: false,
      expandMenu: false,
      moreActionState: null,
      closeNavigation: false,
    };
  },

  watch: {
    moreActionState(newVal) {
      this.beginCollapse = !newVal.isEnd;
      if (!newVal.isEnd) {
        setTimeout(() => {
          this.beginCollapse = false;
          this.expandMenu = newVal.isEnd;
          document.body.classList.remove('disable-scroll');
        }, 455);
      } else {
        this.expandMenu = newVal.isEnd;
        document.body.classList.add('disable-scroll');
      }
    },
  },

  methods: {
    onClickActionItems() {
      this.moreActionState = SmartMorphAnimationState.Init;
    },
  },

};
</script>
