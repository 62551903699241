<template>
  <div class="products-group-outer" v-if="productsState.status.isLoaded">
    <CurrencySelector :selectedCurrency="currency" @changedCurrency="changeCurrency" />
    <div class="products-group" v-if="productsGroupDesc && productsGroupDesc[selectedSport]">
      <div class="products-section-top"
        v-if="productsGroupDesc[selectedSport]['live_score_stats']">
        <h2>{{productsGroupDesc[selectedSport]['live_score_stats'].name}}</h2>
        <div class="desc-area">
          <rz-markdown :text="productsGroupDesc[selectedSport]['live_score_stats'].desc"></rz-markdown>
        </div>
      </div>

      <ProductSection :templateClass="'sub-section'"
      :planDesc="planInfo('basic')"
      :currency="currency" :product="products.rights_basic" />

      <ProductSection :templateClass="'sub-section'"
      :planDesc="planInfo('pro')"
      :currency="currency" :product="products.rights_pro" />

      <ProductSection :templateClass="'sub-section'"
      :planDesc="planInfo('business')"
      :currency="currency" :product="products.rights_business" />

      <template v-if="productsGroupDesc[selectedSport]['enterprise'] && products.rights_enterprise">
        <div class="products-section-top">
          <h2>{{productsGroupDesc[selectedSport]['enterprise'].name}}</h2>
          <div class="desc-area">
            <rz-markdown :text="productsGroupDesc[selectedSport]['enterprise'].desc"></rz-markdown>
          </div>
        </div>

        <ProductSection :templateClass="'sub-section'"
        :planDesc="planInfo('enterprise')"
        :currency="currency" :product="products.rights_enterprise" />
      </template>

      <template v-if="productsGroupDesc[selectedSport]['fantasy']">
        <div class="products-section-top">
          <h2>{{productsGroupDesc[selectedSport]['fantasy'].name}}</h2>
          <div class="desc-area">
            <rz-markdown :text="productsGroupDesc[selectedSport]['fantasy'].desc"></rz-markdown>
          </div>
        </div>

        <ProductSection :templateClass="'sub-section'"
        :planDesc="planInfo('fantasy_silver')"
        :currency="currency" :product="products.fantasy_silver_plan" />

        <ProductSection :templateClass="'sub-section'"
        :planDesc="planInfo('fantasy_gold')"
        :currency="currency" :product="products.fantasy_gold_plan" />
      </template>

      <template v-if="productsGroupDesc[selectedSport]['chatbot'] && products.rights_bot">
        <div class="products-section-top">
          <h2>{{productsGroupDesc[selectedSport]['chatbot'].name}}</h2>
          <div class="desc-area">
            <rz-markdown :text="productsGroupDesc[selectedSport]['chatbot'].desc"></rz-markdown>
          </div>
        </div>

        <ProductSection :templateClass="'sub-section'"
        :planDesc="planInfo('chatbot')"
        :currency="currency" :product="products.rights_bot" />
      </template>

      <template v-if="productsGroupDesc[selectedSport]['performance'] && products.rights_bot">
        <div class="products-section-top">
          <h2>{{productsGroupDesc[selectedSport]['performance'].name}}</h2>
          <div class="desc-area">
            <rz-markdown :text="productsGroupDesc[selectedSport]['performance'].desc"></rz-markdown>
          </div>
        </div>

        <ProductSection :templateClass="'sub-section'"
        :planDesc="planInfo('performance')"
        :currency="currency" :product="products.performance" />
      </template>
    </div>
  </div>
  <div v-else>
    <rz-request-state :state="productsState" />
  </div>
</template>
<style lang="scss">
.products-group-outer {
  position: relative;
  .products-section-top {
    padding-top: var(--section-pad-top);
    padding-left: var(--section-pad-left);
    h2 {
      font-family: var(--hero-font);
    }
    .desc-area {
      .markdown span.p {
        font-size: var(--para-font-size);
        line-height: var(--para-line-height);
        color: var(--hero-text);
      }
    }
  }
}

.products-group {
  .product-section {
    &:nth-child(even) .box-content {
      background-color: var(--box-color-1);
    }
    &:nth-child(odd) .box-content {
      background-color: var(--box-color-2);
    }
  }
}
</style>
<script>
import { RequestState, RequestStateStatus } from '@/api/core';
import { cricketProductsInfo, footballProductsInfo, productsGroupDesc } from '@/store/modules/product';
import { CurrencyObjs } from '@/store/modules/page';

import ProductSection from '@/components/WebPage/Provisional/ProductSection.vue';
import CurrencySelector from '@/components/WebPage/Provisional/CurrencySelector.vue';

export default {
  components: {
    ProductSection,
    CurrencySelector,
  },
  props: {
    groupType: Object,
  },

  data() {
    return {
      products: {},
      selectedSport: 'cricket',
      productsState: new RequestState(),
      currency: CurrencyObjs.EUR.value,
      allCurrencies: CurrencyObjs,
      cricketPlans: cricketProductsInfo,
      footballPlans: footballProductsInfo,
      productsGroupDesc,
    };
  },

  mounted() {
    this.fetchData();
    if (this.getCookie('currency')) {
      this.currency = this.allCurrencies[this.getCookie('currency')].value;
    }
  },

  methods: {
    fetchData() {
      this.productsState.status = RequestStateStatus.Loading;
      if (this.groupType && this.groupType.isFootballPlans) {
        this.selectedSport = 'football';
      }
      const params = { sport: this.selectedSport };
      return this.$apiInstance
        .get('/endpoint/apidocs/products/', { params })
        .then((resp) => {
          if (resp.data.good) {
            this.productsState.status = RequestStateStatus.Loaded;
            this.products = resp.data.data.products;
          }
          console.log('rsp', resp);
        }).catch((err) => {
          console.error(err);
          this.productsState.status = RequestStateStatus.Error;
          this.productsState.ref = err;
        });
    },

    changeCurrency(currencyCode) {
      this.currency = this.allCurrencies[currencyCode].value;
      this.setCookie('currency', this.currency.key);
    },

    planInfo(planKey) {
      let productsInfo = cricketProductsInfo;
      if (this.selectedSport === 'football') {
        productsInfo = footballProductsInfo;
      }
      return productsInfo[planKey];
    },

    getCookie(key) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${key}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
      return null;
    },

    setCookie(cname, cvalue) {
      document.cookie = `${cname}=${cvalue}; path=/`;
    },
  },
};
</script>
