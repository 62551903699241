<template>
  <section class="hero" v-if="pageSection">
    <h2 v-if="pageSection.name">{{pageSection.name}}</h2>
    <div class="section-desc-area">
      <div class="hero-p">
        <rz-markdown v-if="pageSection.desc" :text="pageSection.desc"></rz-markdown>
      </div>
      <div class="image-area" v-if="pageSection.img">
        <rz-image-item :image="pageSection.img" />
      </div>
    </div>
    <template v-if="sectionID === 'code-block'">
      <rz-markdown v-if="pageSection.links[0].url" :text="pageSection.links[0].url"
        class="code-block-section"
      ></rz-markdown>
    </template>
    <template v-else>
      <div v-for="link in pageSection.links" :key="link.key" class="button-wrapper">
        <a v-if="pageSection.links" :href="link.url">{{link.name}}</a>
      </div>
    </template>
  </section>
</template>
<style lang="scss">
  section.hero {
    --subsection-pad-top: #{size(8)};
    --subsection-pad-left: #{size(24)};
    --subsection-para-font-size: #{size(16)};
    --subsection-para-line-height: #{size(22)};
    --codesection-pad-left: #{size(20)};

    --table-font-size: #{size(17)};
    --table-line-height: #{size(24)};
    --table-variable-font-size: #{size(14)};
    --table-variable-line-height: #{size(24)};

    --table-pad-top-bottom: #{size(20)};
    --table-pad-left: #{size(16)};

    @media screen and (min-width: $breakpoint-md) {
      --subsection-pad-top: #{size(12)};
      --subsection-pad-left: #{size(48)};
      --subsection-para-font-size: #{size(18)};
      --subsection-para-line-height: #{size(24)};

      --table-font-size: #{size(21)};
      --table-line-height: #{size(26)};
      --table-variable-font-size: #{size(18)};
      --table-variable-line-height: #{size(30)};

      --table-pad-top-bottom: #{size(32)};
      --table-pad-left: #{size(40)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --subsection-pad-top: #{size(24)};
      --subsection-pad-left: #{size(96)};
      --subsection-para-font-size: #{size(24)};
      --subsection-para-line-height: #{size(36)};

      --table-pad-left: #{size(60)};
    }

    padding-left: var(--section-pad-left);
    .button-wrapper {
      a {
        display: inline-block;
        background: var(--hero-text);
        color: var(--regular-text-inv);
        border-radius: size(4);
        font-size: size(12);
        padding: var(--btn-pad-top) var(--btn-pad-left);
        border: size(1) solid var(--hero-text);
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: .09em;
        text-decoration: none;
        margin-top: size(10);
      }
    }
    .section-desc-area {
      .hero-p {
        .markdown {
          ol >li {
            margin-top: size(15);
          }

          ol >li, span.p {
            max-width: var(--para-2-max-width);
          }

          table {
            margin: var(--table-pad-top-bottom) var(--table-pad-left);
            td {
              font-size: var(--table-font-size);
              line-height: var(--table-line-height);
              padding: size(6) 0;
              color: var(--hero-text);
              &:first-child {
                min-width: size(120);
                @media screen and (min-width: $breakpoint-md) {
                  min-width: size(150);
                }
              }
              &:nth-child(2) {
                color: var(--secondary-text);
                padding-left: size(16);
              }
              .highlight {
                font-size: var(--table-variable-font-size);
                line-height: var(--table-variable-line-height);
                display: inline-block;
              }
            }
          }
        }
      }
    }
    &.sub-section {
      --table-font-size: #{size(14)};
      --table-line-height: #{size(18)};
      --table-variable-font-size: #{size(12)};
      --table-variable-line-height: #{size(18)};

      --table-pad-left: #{size(0)};

      @media screen and (min-width: $breakpoint-md) {
        --table-font-size: #{size(18)};
        --table-line-height: #{size(24)};
        --table-variable-font-size: #{size(14)};
        --table-variable-line-height: #{size(24)};
      }

      .section-desc-area {
        .hero-p {
          .markdown {
            span.p, ol >li {
              font-size: var(--subsection-para-font-size);
              line-height: var(--subsection-para-line-height);
              max-width: var(--para-max-width);
            }
          }
        }
      }
    }
    &.single-column.sub-section-typo .section-desc-area .hero-p .markdown span.p {
      font-size: var(--subsection-para-font-size);
      line-height: var(--subsection-para-line-height);
    }
    &.single-column {
      &.code-block {
        .code-block-section {
          margin-top: size(30);
          background-color: #202746;
          padding: size(25);
          border-radius: size(6);
          display: block;
          @media screen and (min-width: $breakpoint-md) {
            margin-top: size(40);
          }
          &.markdown {
            .p {
              font-size: size(12);
              line-height: size(15);
              font-family: var(--code-font);
              color: #fff;
              overflow: auto;
              @media screen and (min-width: $breakpoint-md) {
                font-size: size(14);
                line-height: size(16);
              }
              .highlight {
                padding: 0;
                color: #8bcd07;
                background: none;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  section.hero {

    padding-top: var(--section-pad-top);
    h2 {
      font-family: var(--hero-font);
      font-size: var(--section-title-font-size);
      line-height: var(--section-title-line-height);
      margin-bottom: size(10);
    }

    .section-desc-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .image-area {
        height: var(--hero-img-height);
        flex: 1 0 auto;
      }
    }
    &.sub-section, .error-code-section {
      padding-top: var(--subsection-pad-top);
      padding-left: var(--subsection-pad-left);
      h2 {
        font-size: var(--title-2-font-size);
        line-height: var(--title-2-line-height);
      }
      .section-desc-area {
        .image-area{
          display: none;
          @media screen and (min-width: $breakpoint-lg) {
            max-width: size(210);
            display: block;
          }
        }
      }
    }

    &.error-code-section {
      padding-left: var(--section-pad-left);
      .section-desc-area {
        padding-bottom: var(--subsection-pad-top);
        padding-left: var(--codesection-pad-left);
      }
      &.show_border .section-desc-area {
        border-bottom: size(2) solid var(--gray-line);
      }
    }

    &.single-column {
      h2 {
        font-size: var(--title-2-font-size);
        line-height: var(--title-2-line-height);
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .section-desc-area {
        flex-direction: row;

        .image-area {
          min-width: size(335);
        }
      }
    }
  }
</style>
<script>

export default {
  props: {
    pageSection: Object,
    sectionID: String,
  },
};
</script>
