<template>
  <div class="json-preview dataclass-wrapper enum-display">
    <ul>
      <li
       v-for="(field, i) in enumDef.fields"
       :key="i"
       class="field dataclass__field__line">
        <span class="name">{{field[0]}}</span>
        <span class="name">{{field[1]}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    enumDef: Object,
  },
};
</script>
