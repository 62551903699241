<template>
  <div class="query-structure">
    <div class="request-item">
      <div class="section-params">
        <div
          class="section-line-item"
          v-for="info in query"
          :key="info.key"
        >
          <div class="params-items item-value">
            <div class="param-item">
              <div class="param-item-property">
                <div class="name"><span>{{info.name}}</span></div>
                <div class="type-desc">
                  <span class="item-type-req">
                    <span class="req" :class="[info.required? 'required' : 'optional']">
                      <span class="type">
                        <span class="type-display">
                          <span>
                            <span class="type-name">
                              <span class="type-name str">{{info.type}}</span>
                            </span>
                          </span>
                        </span>
                      </span>
                      {{info.required? 'required' : 'optional'}}
                    </span>
                  </span>
                  <div class="help">
                    <p v-if="info.desc"><rz-markdown class="clean" :text="info.desc" /></p>
                  </div>
                </div>
                <div class="desc-column">
                  <p v-if="info.desc"><rz-markdown class="clean" :text="info.desc" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" >
.markdown {
    a {
      color: var(--hero-text);
    }

    em, h1, h2, h3, h4 {
      font-weight: 600;
      color: var(--secondary-text);
    }

    h1, h2, h3, h4 {
      padding-bottom: size(8);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
  }

  @media screen and (min-width: $breakpoint-lg) {
    .markdown {
      h1, h2, h3, h4 {
        padding-bottom: size(12);
      }
    }
  }
</style>

<style lang="scss" scoped>
.query-structure{
  --label-width: #{size(100)};
  --item-content-padding-top: #{size(16)};
  --item-content-padding-bottom: #{size(18)};
  --item-padding-bottom: #{size(24)};
  --req-font-size: #{size(12)};
  --req-line-height: #{size(20)};
  --req-token-font-size: #{size(10)};
  --req-token-line-height: #{size(13)};
  --req-desc-font-size: #{size(14)};
  --req-desc-line-height: #{size(17)};
  --hero-color-with-opacity: rgba(0, 83, 214, 0.2);

  font-size: var(--req-font-size);

  @media screen and (min-width: $breakpoint-md) {
    --item-content-padding-bottom: #{size(20)};
    --req-token-font-size: #{size(12)};
    --req-token-line-height: #{size(15)};
    --req-font-size: #{size(14)};
    --req-line-height: #{size(20)};
    --item-padding-bottom: #{size(32)};
    --req-desc-font-size: #{size(16)};
    --req-desc-line-height: #{size(19)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --item-content-padding-top: #{size(19)};
    --req-line-height: #{size(20)};
    --item-padding-bottom: #{size(36)};
    --req-desc-font-size: #{size(18)};
    --req-desc-line-height: #{size(24)};
  }

  .request-item {
    padding-right: var(--docs-page-pad-secondary-right);
    .section-line-item {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--item-content-padding-bottom);

      .item-value {
        flex-grow: 1;

        &.space-top {
          padding-top: size(20);
        }
      }
    }

    .section-url {
      .section-line-item {
        .item-value {
          font-family: var(--code-font);
        }
      }
    }

    .section-params {
      .section-line-item {
        border-bottom: size(1) solid var(--regular-text-inv);
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
          .item-value {
            .param-item {
              &:last-child {
                .param-item-property {
                  padding-bottom: 0;
                }
              }
              .param-item-property {
                .name {
                  @media screen and (min-width: $breakpoint-md) {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }
        .item-value {
          &:last-child {
            margin-bottom: 0;
          }
          .param-item {
            &:first-child {
              .param-item-property {
                padding-top: 0;
              }
            }

            .param-item-property {
              padding: size(6) 0;
              flex-direction: column;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(12) 0;
              }
              @media screen and (min-width: $breakpoint-lg) {
                display: flex;
                flex-direction: row;
                padding: size(16) 0;
              }
              .type-desc {
                display: inline;
              }

              .name {
                padding-right: size(12);
                font-size: var(--req-font-size);
                line-height: var(--req-line-height);
                font-weight: 700;
                color: var(--hero-text);
                font-family: var(--code-font);
                display: inline-block;
                text-align: left;
                @media screen and (min-width: $breakpoint-md) {
                  padding-right: size(30);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  min-width: var(--label-width);
                }
              }
              .type-desc {
                @media screen and (min-width: $breakpoint-md) {
                  min-width: size(180);
                  padding-right: size(30);
                }
                .markdown {
                  .p {
                    padding-top: 0;
                  }
                }
              }


              .item-type-req {
                display: inline-block;
                .type {
                  padding: size(6) size(4);
                  margin-left: size(-1);
                  border-radius: size(14);
                  background: var(--hero-text);
                  color: var(--regular-text-inv);
                  font-family: var(--code-font);
                }
                .req {
                  display: inline-block;
                  padding: size(5.25) size(6);
                  padding-left: 0;
                  border-radius: size(14);
                  color: var(--hero-text);
                  font-style: normal;
                  text-transform: capitalize;
                  background: transparent;
                  border: size(0.75) solid var(--hero-text);
                  margin-bottom: size(8);
                  &.required {
                    padding: size(6);
                    padding-left: 0;
                    background: var(--hero-color-with-opacity);
                    border: none;
                  }
                  @media screen and (min-width: $breakpoint-lg) {
                    border: size(1) solid var(--hero-text);
                  }
                }
              }

              .req, .type {
                font-size: var(--req-token-font-size);
                line-height: var(--req-token-line-height);
              }

              .help {
                .markdown {
                  .p {
                    font-size: var(--req-desc-font-size);
                    line-height: var(--req-desc-line-height);

                    a {
                      text-decoration: underline;
                      font-size: var(--req-token-font-size);
                      line-height: var(--req-token-line-height);
                    }
                  }
                }
                display: block;
                @media screen and (min-width: $breakpoint-md) {
                  display: none
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.query-structure {
  .desc-column {
    display: none;
    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
    .markdown {
      .p {
        padding-top: 0;
        strong {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
<script>

export default {
  props: {
    query: Array,
  },
};
</script>
