<template>
  <div class="code-display">
    <div class="section-title" v-if="showTitle">
      <span class="filename" v-if="code.sample_filename">{{code.sample_filename}}</span>
      <span class="lang" v-else-if="code.lang">{{code.lang}}</span>
    </div>
    <div class="code">
      <div class="copy-context">{{codeText}}</div>
      <pre><code
          :class="classForCode"
          v-if="code.inline_code" ref="code">{{codeText}}</code></pre>
      <div class="copy-button" @click="copyCode(codeText)">
        <img class="copy-icon" src="@/assets/copy-icon.svg" alt="icon copy" />
      </div>
    </div>
    <span v-if="successMessage" class="success-message">Copied</span>
  </div>
</template>
<style lang="scss">
  .code-display {
    margin: size(20) var(--docs-page-pad-secondary-right) size(10) size(30);
    position: relative;
    // max-width: size(600);
    @media screen and (min-width: $breakpoint-lg) {
      margin: size(30) var(--docs-page-pad-secondary-right) size(7) size(30);
    }
    .section-title {
      font-size: size(12);
      padding: size(8);
      padding-left: 0;
      @media screen and (min-width: $breakpoint-lg) {
        // flex-basis: size(130);
        // padding: size(7) size(32);
        .lang, .filename {
          padding: 0;
          margin: 0.3em;
          display: inherit;
        }
      }
      // @media screen and (max-width: $breakpoint-md) {
      //   padding: size(7) size(12);
      // }

      .lang {
        text-transform: uppercase;
      }
      .lang, .filename {
        font-size: size(14);
        line-height: size(17);
        margin: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(16);
          line-height: size(19);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(18);
          line-height: size(22);
        }
      }
    }
    .code {
      padding: size(12) size(16);
      overflow: auto;
      background-color: var(--box-color-2);
      position: relative;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(16) size(16);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(18) size(16);
      }
      .language-bash {
        font-size: size(12);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(14);
        }
      }
      pre {
        word-wrap: break-word;
        padding: 0;
        margin: 0;
        @media screen and (min-width: $breakpoint-lg) {
          padding: 0;
          margin: 0;
        }
      }

      code {
        font-family: var(--code-font);
        word-wrap: break-word;
      }
    }

    .copy-context {
      display: none;
    }

    .success-message {
      font-size: size(12);
      line-height: size(15);
      background: var(--gray-line);
      color: var(--regular-text);
      padding: size(5) size(10);
      border-radius: size(12);
      position: absolute;
      top: size(3);
      right: size(0);
      z-index: 999;
      transition: opacity 0.5s linear;
      animation: fadein  0.5s linear;
      @keyframes fadein {
        from {
          opacity: 0;
        }
        to  {
          opacity: 1;
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        right: size(8);
      }
      @media screen and (min-width: $breakpoint-lg) {
        top: size(8);
        right: size(10);
      }
    }
    .copy-button {
      position: absolute;
      right: size(8);
      top: size(6);
      width: size(30);
      height: size(30);
      background-color: var(--hero-text);
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      line-height: size(26);
      .copy-icon {
        vertical-align: middle;
      }
      &:hover {
        opacity: 0.8;
      }
      @media screen and (min-width: $breakpoint-md) {
        top: size(12);
        right: size(22);
      }
      @media screen and (min-width: $breakpoint-lg) {
        top: size(15);
        right: size(24);
      }
    }
  }
</style>
<script>
import '@/assets/styles/views/code_theme_bright.scss';

import 'prismjs/themes/prism.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-javascript';

// http://k88hudson.github.io/syntax-highlighting-theme-generator/www/
// https://github.com/PrismJS/prism/tree/master/components

export default {
  props: {
    code: Object,
    showTitle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      successMessage: false,
    };
  },

  watch: {
    codeText() {
      this.render();
    },
  },

  computed: {

    classForCode() {
      return { [`language-${this.lang}`]: true };
    },

    lang() {
      const code = this.code.lang.toLowerCase();
      const map = {
        terminal: 'bash',
        curl: 'bash',
        nodejs: 'javascript',
      };

      if (map[code]) {
        return map[code];
      }

      return code;
    },

    codeText() {
      if (this.code.inline_code) {
        return this.code.inline_code.trim();
      }

      return '';
    },
  },

  mounted() {
    this.render();
  },

  methods: {
    render() {
      const useAsync = false;
      this.$nextTick(() => {
        // console.log('Rendering', this.codeText);
        if (this.$refs.code) {
          this.$refs.code.textContent = this.codeText;
          Prism.highlightElement(this.$refs.code, useAsync);
        }
      });
    },

    preRender(code) {
      return code.replace(/\s+data-v-\S+="[^"]*"/g, '');
    },

    copyCode(code) {
      const el = document.createElement('textarea');
      el.value = code;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successMessage = true;
      setTimeout(() => {
        this.successMessage = false;
      }, 1000);
    },
  },
};
</script>
