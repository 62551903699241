<template>
  <div class="api-data-http-status">
    <div class="loader" v-if="!state.status.isLoaded">
      <rz-request-state :state="state" />
    </div>
    <div v-else-if="page && page.detail">
      <div>
        <div class="hide-on-desktop">
          <table>
            <thead>
              <tr>
                <th>Status Code</th>
                <th>Error Code</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="status in groupedData.specific"
                :class="[status.statusClass]"
              >
                <template>
                  <tr
                  :key="status.key"
                >
                    <td class="http">
                      <div class="http-status-code">
                        {{status.http_code}}
                      </div>
                    </td>
                    <td class="code">
                      <rz-json-path
                        :text="status.code" v-if="status.code"/>
                    </td>
                    <td class="spacer">&nbsp;</td>
                  </tr>
                  <tr
                    :key="status.key"
                    class="reason-row">
                    <td class="reason" colspan="2">
                      <HTTPReasonDesc
                        :status="status"
                        :responseState="responseState"
                        :page="page"
                        @showResponse="$emit('showResponse', response)" />
                    </td>
                    <td class="spacer">&nbsp;</td>
                  </tr>
                </template>
              </template>
              <template v-if="groupedData.specific && groupedData.specific.length > 0">
                <tr
                :class="[groupedData.specific && groupedData.specific.length > 0 ? 'button-container': 'hide']">
                  <td colspan="2">
                    <img v-show="!expand" class="expand-icon"
                     @click="expand = !expand" src="@/assets/http-expand-icon.svg"/>
                    <img v-show="expand" class="expand-icon"
                     @click="expand = !expand" src="@/assets/http-collapse-icon.svg"/>
                    <button :class="[expand ? 'add-pad': 'remove-pad']" @click="expand = !expand">
                      Other common HTTP Status Codes</button>
                  </td>
                </tr>
              </template>
              <template
                v-for="status in groupedData.common"
                :class="[status.statusClass]"
              >
                <template v-if="expand">
                  <tr
                  :key="status.key"
                  >
                    <td class="http">
                      <div class="http-status-code">
                        {{status.http_code}}
                      </div>
                    </td>
                    <td class="code">
                      <rz-json-path
                        :text="status.code" v-if="status.code"/>
                    </td>
                    <td class="spacer">&nbsp;</td>
                  </tr>
                  <tr
                    :key="status.key"
                    class="reason-row">
                    <td class="reason" colspan="2">
                      <HTTPReasonDesc
                        :status="status"
                        :responseState="responseState"
                        :page="page"
                        @showResponse="$emit('showResponse', response)" />
                    </td>
                    <td class="spacer">&nbsp;</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>

        <div class="show-on-desktop">
          <table>
            <thead>
              <tr>
                <th>Status Code</th>
                <th >Error Code</th>
                <th>Reason</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="status in groupedData.specific">
                <tr
                  :key="status.key"
                >
                  <td class="http">
                    <div class="http-status-code">
                      {{status.http_code}}
                    </div>
                  </td>
                  <td class="code">
                    <rz-json-path
                      :text="status.code" v-if="status.code"/>
                  </td>
                  <td class="reason">
                    <HTTPReasonDesc
                      :status="status"
                      :responseState="responseState"
                      :page="page"
                      @showResponse="$emit('showResponse', response)" />
                  </td>
                  <td class="spacer">&nbsp;</td>
                </tr>
              </template>
              <template v-if="groupedData.specific && groupedData.specific.length > 0">
                <tr
                :class="[groupedData.specific && groupedData.specific.length > 0 ? 'button-container': 'hide']">
                  <td colspan="3">
                    <img v-show="!expand" class="expand-icon"
                     @click="expand = !expand" src="@/assets/http-expand-icon.svg"/>
                    <img v-show ="expand" class="expand-icon"
                     @click="expand = !expand" src="@/assets/http-collapse-icon.svg"/>
                    <button :class="[expand ? 'add-pad': 'remove-pad']" @click="expand = !expand">
                      Other common HTTP Status Codes</button>
                  </td>
                </tr>
              </template>
              <template  v-for="status in groupedData.common">
                <tr
                  v-if="expand"
                  :key="status.key"
                >
                  <td class="http">
                    <div class="http-status-code">
                      {{status.http_code}}
                    </div>
                  </td>
                  <td class="code">
                    <rz-json-path
                      :text="status.code" v-if="status.code"/>
                  </td>
                  <td class="reason">
                    <HTTPReasonDesc
                      :status="status"
                      :responseState="responseState"
                      :page="page"
                      @showResponse="$emit('showResponse', response)" />
                  </td>
                  <td class="spacer">&nbsp;</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .api-data-http-status {
    --error-border: #B097FC;
    --error-bg: rgba(176, 151, 252, 0.2);
    --http-status-circle-size: #{size(30)};
    --table-header-title: #{size(36)};

    --td-first-pad-top: #{size(12)};
    --td-pad-top: #{size(12)};
    --td-pad-left: #{size(8)};
    --td-pad-bottom: #{size(12)};

    --http-status-font-size: #{size(14)};
    --http-status-line-height: #{size(17)};
    --http-error-font-size: #{size(14)};
    --http-error-line-height: #{size(18)};
    --http-reason-font-size: #{size(14)};
    --http-reason-line-height: #{size(17)};

    @media screen and (min-width: $breakpoint-md) {
      --http-status-circle-size: #{size(40)};
      --table-header-title: #{size(60)};

      --td-first-pad-top: #{size(38)};
      --td-pad-top: #{size(16)};
      --td-pad-left: #{size(16)};
      --td-pad-bottom: #{size(16)};

      --http-reason-font-size: #{size(16)};
      --http-reason-line-height: #{size(19)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --http-status-circle-size: #{size(50)};
      --table-header-title: #{size(70)};

      --td-first-pad-top: #{size(38)};
      --td-pad-top: #{size(16)};
      --td-pad-left: #{size(16)};
      --td-pad-bottom: #{size(16)};

      --http-status-font-size: #{size(16)};
      --http-status-line-height: #{size(19)};
      --http-reason-font-size: #{size(18)};
      --http-reason-line-height: #{size(24)};
    }

    text-align: left;
    font-size: var(--para-font-size);
    line-height: var(--para-line-height);

    .hide-on-desktop {
      display: inherit;

      @media screen and (min-width: $breakpoint-lg) {
        display: none;
      }
      table {
        th {
          &:first-child {
            width: size(100);
            @media screen and (min-width: $breakpoint-md) {
              width: size(150);
            }
          }
        }
      }
    }

    .show-on-desktop {
      display: none;

      @media screen and (min-width: $breakpoint-lg) {
        display: inherit;
      }
    }
    table {
      width: 100%;
      thead {
        th {
          line-height: var(--table-header-title);
          background: var(--regular-text-inv);
          white-space: nowrap;
          padding-left: var(--td-pad-left);
          &:first-child {
            @media screen and (min-width: $breakpoint-lg) {
              text-align: center;
            }
          }
        }
      }
      tr {
        &:first-child {
          td {
            padding-top: var(--td-first-pad-top);
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      td {
        padding:
          var(--td-pad-top)
          0
          var(--td-pad-bottom)
          var(--td-pad-left);

        &.http {
          max-width: size(110);
          @media screen and (min-width: $breakpoint-lg) {
            text-align: center;
          }
          .http-status-code {
            font-size: var(--http-status-font-size);
            line-height: var(--http-status-line-height);
          }
        }
        &.code {
          max-width: size(200);
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: size(20);
          }
          .json-path {
            background-color: var(--error-bg);
            border-left: size(4) solid var(--error-border);
            font-size: var(--http-error-font-size);
            line-height: var(--http-error-line-height);
            padding: 0 size(4);
            margin-bottom: 0;
          }
        }

        &.reason {
          min-width: size(200);
          max-width: size(300);
          font-size: var(--http-reason-font-size);
          line-height: var(--http-reason-line-height);
          @media screen and (min-width: $breakpoint-md) {
            min-width: size(440);
            max-width: size(440);
          }
          .status-reason {
            font-weight: bold;
            display: inline-block;
          }
          .desc-expand-icon , .inv-icon{
            display: inline-block;
            cursor: pointer;
            vertical-align: middle;
            margin-left: size(4);
            transition: transform 0.3s linear;
            transform: rotate(0deg);
          }
          .inv-icon {
            transition: transform 0.3s linear;
            transform: rotate(180deg);
          }
          .hide {
            display: none;
          }
          .desc {
            padding-top: size(12);
          }
          .response-items, .links {
            padding-top: size(12);
          }
        }

        &.spacer {
          padding: 0;
          width: var(--docs-page-pad-secondary-right);
          border-bottom: none;
        }
      }
      .hide {
        display: none;
      }
      .button-container {
        td {
          border-bottom: none;
          padding: 0;
          padding-top: size(16);
        }
        button {
          outline: none;
          border: none;
          font-size: var(--http-reason-font-size);
          line-height: var(--http-reason-line-height);
          margin-left: size(8);
          cursor: pointer;
          &.add-pad {
            padding-bottom: size(18);
          }
          &.remove-pad {
            padding-bottom: 0;
          }
        }
        .hide {
          display: none;
        }
        img {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          width: size(20);
          height: size(20);
          @media screen and (min-width: $breakpoint-md) {
            width: size(30);
            height: size(30);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(20);
            height: size(20);
          }
        }
      }

      .reason-row {
        td {
          padding-top: 0;
          border-bottom: size(2) solid var(--regular-text-inv);
          &.spacer {
            border-bottom: none;
          }
        }
      }

      .no-border {
        td {
          border-bottom: none;
        }
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      table {
        td {
          border-bottom: size(1) solid var(--regular-text-inv);
        }
      }
    }
  }
</style>

<script>
import HTTPReasonDesc from '@/components/API/HTTPReasonDesc.vue';

export default {
  components: { HTTPReasonDesc },
  data() {
    return {
      expand: true,
    };
  },
  props: {
    state: Object,
    responseState: Object,
    page: Object,
  },
  computed: {
    groupedData() {
      if (this.page
        && this.page.detail
        && this.page.detail.status_codes
        && this.page.detail.status_codes.length > 0) {
        const result = {};
        const array = this.page.detail.status_codes;
        const key = 'is_common';
        array.forEach((item) => {
          let groupName = 'common';
          if (!item[key]) {
            groupName = 'specific';
          }
          if (!result[groupName]) {
            result[groupName] = [];
          }
          if (result && result.specific && result.specific.length > 0) {
            this.expand = false;
          }
          result[groupName].push(item);
        });
        return result;
      }
      return true;
    },
  },
};
</script>
