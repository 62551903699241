<template>
  <div class="web-page" v-if="page">
    <div class="loader" v-if="!pageState.status.isLoaded">
      <LoadingPlaceholder :state="pageState" />
    </div>
    <div class="content" v-else>
      <GotoTop />
      <HeroBanner :page="page" :templateClass="page.route_value" />
      <div class="data-wrapper" v-if="page.route_value === 'data-structure-docs'">
        <DataStructure
          v-for="(section, i) in page.sections"
          :key="i"
          :index="i"
          :pageSection="section.detail"
          :class="['data-structure' ,page.route_value +'-' + i]"
        >
        </DataStructure>
        <section class="pull-left page-support-section">
          <SupportSection />
        </section>
      </div>
      <template
        v-else
        >
        <template v-for="(section, i) in page.sections">
          <PageSection
            :key="i"
            :id="`goto-section-${i}`"
            :section="section"
          >
          </PageSection>
        </template>
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.web-page {
  .support-section {
    h2 {
      font-family: var(--hero-font);
    }
  }
}
</style>
<style lang="scss" scoped>
.web-page{
  --web-page-pad-top: #{size(20)};
  --web-page-pad-right: #{size(16)};
  --web-page-pad-bottom: #{size(20)};
  --web-page-pad-left: #{size(0)};
  --web-page-pad-secondary-right: #{size(16)};
  --support-section-top-pad: #{size(27)};

  @media screen and (min-width: $breakpoint-md) {
    --web-page-pad-top: #{size(30)};
    --web-page-pad-right: #{size(24)};
    --web-page-pad-bottom: #{size(32)};
    --web-page-pad-left: #{size(28)};
    --web-page-pad-secondary-right: #{size(24)};
    --support-section-top-pad: #{size(60)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --web-page-pad-top: #{size(54)};
    --web-page-pad-right: #{size(55)};
    --web-page-pad-bottom: #{size(52)};
    --web-page-pad-left: #{size(46)};
    --web-page-pad-secondary-right: #{size(150)};
    --support-section-top-pad: #{size(100)};
  }

  padding:
    var(--web-page-pad-top)
    0
    var(--web-page-pad-bottom)
    0;

  .data-wrapper {
    display: flex;
    flex-wrap: wrap;
    .page-support-section {
      width: 100%;
      margin-top: 0;
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';

import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';
import PageSection from '@/components/WebPage/PageSection.vue';
import HeroBanner from '@/components/WebPage/HeroBanner.vue';
import GotoTop from '@/components/GotoTop.vue';
import DataStructure from '@/components/WebPage/DataStructure.vue';
import SupportSection from '@/components/SupportSection.vue';

export default {
  components: {
    HeroBanner,
    PageSection,
    GotoTop,
    LoadingPlaceholder,
    DataStructure,
    SupportSection,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      seniorMenuState: (state) => state.link.seniorMenuState,
    }),
  },

  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
        || to.params.sport !== from.params.sport
        || to.params.page !== from.params.page
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (this.dataLoaded) {
      console.log('Mounted page', this.page.route_value, this.page.sports);
      let sameRoute = this.page.route_value === this.$route.params.page;
      sameRoute = sameRoute && this.page.sports === this.$route.params.sport;

      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.fetchData();
    }
  },

  methods: {
    fetchData() {
      let routeSuffix = 'docs/guides/';
      let sportValue = this.$route.params.sport;
      if ((!sportValue) && this.$route.meta && this.$route.meta.sportValue) {
        sportValue = this.$route.meta.sportValue;
      }
      if (this.$route.name === 'docsPage') {
        routeSuffix = 'pages/';
      }
      const route = `/${sportValue}/${routeSuffix}`;
      let routeValue = this.$route.params.page;
      if ((!routeValue) && this.$route.meta && this.$route.meta.routeValue) {
        routeValue = this.$route.meta.routeValue;
      }
      return this.$store.dispatch('page/fetchActivePage', { route, routeValue }).catch((err) => {
        console.error('Error on fetch Active Page', err);
      });
    },

    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.fetchData();
    },

  },
};
</script>
