<template>
  <div class="junior-menu" :class="{
    expanded: openState.expanded,
    height: $route.meta.routeValue === 'graphQL'
    }">
    <div class="goto" @click="onSubMenuClick()">
      <div>Go to</div>
      <div class="icon-box">
        <DropdownIcon
          :rootClass="openState.expanded ? 'regular-inv' : 'color-secondary'"
          :state="openState.animationState"
          @updateState="(state) => {updateLinkAnimationState(state)}"
          @iconClick="(state) => {onSubMenuIconClick(state)}"
        />
      </div>
    </div>
    <ul>
      <li
      v-for="item in links"
      :key="item.hash">
        <a
          :href="'#' + item.hash"
          @click="scrollToElement(item.hash, $event)"
          >{{item.name}}</a>
      </li>
    </ul>
  </div>
</template>
<style lang="scss">
.junior-menu {

  --junior-menu-dropdown-size: #{size(12)};
  --junior-menu-title-font-size: #{size(14)};

  @media screen and (min-width: $breakpoint-lg) {
    --junior-menu-title-font-size: #{size(16)};
    --junior-menu-dropdown-size: #{size(20)};
  }

  position: fixed;
  z-index: var(--junior-menu-z-index);
  right: var(--junior-menu-pad-right);
  top: var(--master-menu-height);
  margin-top: var(--junior-menu-pad-top);

  padding: 0 size(32);
  background: var(--junior-menu-inactive);
  color: var(--secondary-text);
  width: calc(var(--junior-menu-width) - (32 * 2));
  max-width: calc(var(--junior-menu-width) - (32 * 2));
  height: var(--junior-menu-height);
  overflow: hidden;

  transition: all 350ms ease-out;
  display: none;

  &:hover, &.expanded {
    background: var(--hero-text);
    color: var(--regular-text-inv);

    a {
      color: var(--regular-text-inv);
    }

    .default-stroke {
      stroke: var(--regular-text-inv);
    }

  }

  &.expanded {
    height: size(240);
    @media screen and (min-width: $breakpoint-lg) {
      height: size(260);
    }
    &.height {
      height: size(190);
    }
  }

  .goto {
    font-size: var(--junior-menu-title-font-size);
    display: flex;
    justify-content: space-between;
    height: var(--junior-menu-height);
    cursor: pointer;

    :nth-child(1) {
      @include flex-center;
    }

    :nth-child(2) {
      @include flex-center;

      .svg-icon {
        width: var(--junior-menu-dropdown-size);
        height: var(--junior-menu-dropdown-size);
      }
    }
  }

  ul {
    font-size: size(12);
    line-height: size(12);
    letter-spacing: 0.09em;
    text-transform: uppercase;
    li {
      &:first-child {
        padding-top: size(8);
      }
      a {
        padding-bottom: size(12);
        display: block;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}
.master-menu-overlay {
  .junior-menu:not(.expanded) {
    background-color: var(--master-bg-lite);
    &:hover {
      background-color: var(--hero-text);
    }
  }
}
</style>
<script>
import { SmartMorphAnimationState } from '@/core/view/smartMorph';
import { LinkStateData } from '@/store/modules/link';
import DropdownIcon from '@/components/InteractiveIcons/DropdownIcon.vue';

export default {
  components: { DropdownIcon },
  props: {
    links: Array,
  },

  data() {
    return {
      initY: null,
      openState: new LinkStateData(),
    };
  },

  destroyed() {
    window.removeEventListener('scroll', this.onWindowScroll);
  },

  methods: {
    onWindowScroll() {
      const deltaY = Math.abs(window.scrollY - this.initY);
      if (deltaY > 15) {
        this.animateAndClose();
      }
    },

    addScrollEvent() {
      this.initY = window.scrollY;
      window.addEventListener('scroll', this.onWindowScroll);
    },

    removeScrollEvent() {
      window.removeEventListener('scroll', this.onWindowScroll);
    },

    scrollToElement(elementId, event) {
      if (event) {
        event.preventDefault();
      }

      document
        .getElementById(elementId)
        .scrollIntoView({ behavior: 'smooth' });

      this.animateAndClose();
    },

    onSubMenuIconClick(state) {
      if (!state.isEnd) {
        this.closeSubMenu();
      } else {
        this.openSubMenu();
      }
    },

    onSubMenuClick() {
      if (this.openState.expanded) {
        this.animateAndClose();
      } else {
        this.updateLink({ animationState: SmartMorphAnimationState.End });
        this.openSubMenu();
      }
    },

    openSubMenu() {
      this.addScrollEvent();
      this.updateLink({ beginCollapse: false, expanded: true });
    },

    closeSubMenu() {
      this.removeScrollEvent();
      this.updateLink({ beginCollapse: true });

      // Wait for animation to finish
      setTimeout(() => {
        this.updateLink({ beginCollapse: false, expanded: false });
      }, 455);
    },

    animateAndClose() {
      this.updateLink({ animationState: SmartMorphAnimationState.Init });
      this.closeSubMenu();
    },

    updateLinkAnimationState(animationState) {
      this.updateLink({ animationState });
    },

    updateLink(newState) {
      Object.keys(newState).forEach((key) => {
        this.openState[key] = newState[key];
      });
    },

    onLinkClick() {
      this.closeSubMenu();
    },
  },
};
</script>
