<template>
  <div class="page-goto-top" @click="gotoTop()" v-if="show">
    <span class="label">Back to top</span>
    <img src="@/assets/icon-long-arrow.svg" alt="icon long arrow"/>
  </div>
</template>
<style lang="scss">
  .page-goto-top {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: rotate(-90deg);
    transform-origin: right;
    z-index: var(--goto-top-z-index);
    display: none;
    cursor: pointer;

    @media (any-pointer: fine) {
      display: block;
    }

    @media screen and (min-width: $breakpoint-lg) {
      display: block;
    }

    .label {
      padding-right: size(22);
      font-size: size(12);
      line-height: size(15);
      display: inline-block;
      overflow: hidden;
      color: var(--hero-text);
      letter-spacing: 0.09em;
      text-transform: uppercase;
      vertical-align: middle;
    }

    img {
      height: size(15);
      vertical-align: middle;
      // Block space while image loading
      min-width: size(24);
    }
  }
</style>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 350) {
        this.show = true;
      } else if (this.show) {
        this.show = false;
      }
    });
  },

  methods: {
    gotoTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
