<template>
  <div v-if="page.related_apis" class="api-data-related-apis">
    <h3>Related APIs</h3>
    <ul>
      <li
        v-for="(link, i) in page.related_apis"
        :key="i">
        <rz-link-item :linkClass="'btn rounded smaller-on-mobile'" :link="link" />
      </li>
    </ul>
  </div>
</template>
<style lang="scss">
  .api-data-related-apis {
    color: var(--hero-text);

    h3 {
      text-transform: uppercase;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      padding-top: var(--section-box-pad-top);

      li {
        padding: 0 size(28) size(28) 0;
      }
    }
    .btn {
      &.smaller-on-mobile {
        @media screen and (min-width: $breakpoint-md) {
          padding: size(14) size(16);
        }
      }
    }
  }
</style>

<script>
export default {
  props: {
    page: Object,
  },
};
</script>
