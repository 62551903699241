export class APISchemaRequest {
  sourceName(name) {
    if (name === 'Path') {
      return 'URL Params';
    }

    if (name === 'Header') {
      return 'Header Params';
    }

    return name;
  }

  static createFromJson(raw) {
    let data = new APISchemaRequest();
    data = Object.assign(data, raw);
    return data;
  }
}

export class APISchemaResponse {
  static createFromJson(raw) {
    let data = new APISchemaResponse();
    data = Object.assign(data, raw);
    return data;
  }
}

export class APISchemaDefinition {
  static createFromJson(raw) {
    let data = new APISchemaDefinition();
    data = Object.assign(data, raw);
    data.request = APISchemaRequest.createFromJson(raw.request);
    data.response = APISchemaResponse.createFromJson(raw.response);
    return data;
  }
}

export class APISchema {
  get requestPath() {
    return this.fullRequestPath(this.definition.path);
  }

  get requestPathAliases() {
    return this.definition.path_aliases.map((x) => this.fullRequestPath(x));
  }

  get requestPaths() {
    const paths = [this.requestPath];
    if (this.requestPathAliases) {
      paths.push(...this.requestPathAliases);
    }

    // This order must come from server
    // but due its design, it may be complex to acheive
    // so its sorted here
    paths.sort((x, y) => x.length - y.length);

    return paths;
  }

  fullRequestPath(path) {
    return `${this.group_url_prefix}${path}`;
  }

  static createFromJson(raw) {
    let data = new APISchema();
    data = Object.assign(data, raw);
    data.definition = APISchemaDefinition.createFromJson(raw.definition);
    return data;
  }

  static fetch(apiInstance, key) {
    const params = { key };
    return apiInstance
      .get('/endpoint/apidocs/api-schema/', { params })
      .then((resp) => APISchema.createFromJson(resp.data.data.schema));
  }
}
