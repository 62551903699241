<template>
  <div
    class="json-preview change-text-color dataclass-display dataclass-wrapper"
    :class="[level > 0? 'child-level' : 'root-level', 'level-' + level]">
    <ul>
      <li class="field dataclass__field__line"
      v-for="field in dataclass.fields"
      :key="field.name">
        <span class="name" :class="{required:field.required}">{{field.name}}</span>
        <!-- Need to fix this -->
        <span class="type-display type" v-if="field.type_name === ':LIST::LIST:builtins.int'
          || field.type_name === ':LIST::LIST:builtins.float'">
          <span class="type-collection-display">
            <span class="type-collection type-collection-wrapper">
              LIST(
            </span>
            <span class="type-name">
              <span class="type-name int">{{field.type_name === ':LIST::LIST:builtins.int'
                  ?
                    'LIST(int)'
                  :
                    'LIST(float)'
                }}
              </span>
            </span>
            <span class="type-collection-wrapper">
            )
            </span>
          </span>
        </span>
        <type-display
          v-if="field.type_name !== ':LIST::LIST:builtins.int' && field.type_name !== ':LIST::LIST:builtins.float'"
          @showSchemaDefinition="showSchemaDefinition"
          class="type"
          :type="field.type_name"
          :defs="defs"></type-display>
        <span class="default" v-if="field.default && field.default != 'NOTHING'">{{field.default}}</span>
        <p class="help-text" v-if="field.help_text">{{field.help_text}}</p>
        <template v-if="level < 3">
          <dataclass-display
          @showSchemaDefinition="showSchemaDefinition"
          v-for="path in field.related_defs"
          :key="path"
          :dataclass="defs[path]"
          :level="level+1"
          :defs="defs"
          :parentPath="childPath(field.key)"></dataclass-display>
        </template>
        <!-- <span v-else-if="field.related_defs.length">Click to see</span> -->
      </li>
    </ul>
  </div>
</template>
<script>
import '@/assets/styles/components/json_preview.scss';

import TypeDisplay from './TypeDisplay.vue';

export default {
  name: 'dataclassDisplay',
  components: { TypeDisplay },
  props: {
    dataclass: Object,
    defs: Object,
    level: {
      type: Number,
      default: 0,
    },
    parentPath: {
      type: String,
      default: '',
    },
  },
  methods: {
    childPath(key) {
      if (this.parentPath) {
        return `${this.parentPath}.${key}`;
      }
      return key;
    },

    showSchemaDefinition(defs, path) {
      this.$emit('showSchemaDefinition', defs, path);
    },
  },
};
</script>
