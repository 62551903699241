<template>
  <div :class="[overlayMasterMenu ? 'master-menu-overlay' : null]" class="docs-wrap">
    <MasterMenu
      :class="[overlayMasterMenu ? 'overlay-menu' : null]"
      :menuItems="masterLinks"
      :menuBehaviour="overlayMasterMenu"
      v-if="masterLinks.length"/>
    <div class="page-container">
      <div class="page">
        <div class="page-docs split-cols">
          <SeniorMenu
            class="fixed-col"
            :linkslist="seniorLinksList" />
          <router-view class="flex-col"></router-view>
        </div>
      </div>
    </div>
    <Footer :class="$route.name"/>
  </div>
</template>
<style lang="scss">
  .page-container {
    max-width: var(--content-max-width);
    margin: auto;
  }
  .docs-wrap {
    margin-top: var(--master-menu-height);
  }
</style>
<script>
import Footer from '@/components/Footer.vue';
import MasterMenu from '@/components/MasterMenu.vue';
import SeniorMenu from '@/components/SeniorMenu.vue';
import { mapState } from 'vuex';

export default {
  components: {
    MasterMenu, SeniorMenu, Footer,
  },

  computed: {
    ...mapState({
      overlayMasterMenu: (state) => state.overlayMasterMenu,
      dataLoaded: (state) => state.link.cricketDocsSenior.name !== undefined,

      seniorLinksList: (state) => state.link.cricketDocsSenior,
      masterLinks: (state) => state.link.cricketMaster.links,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  mounted() {
    if (!this.dataLoaded) {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      return this.$store.dispatch('link/fetchCricketDocsSenior').then(() => {
      }).catch((err) => {
        console.log('Error on Senior Menu', err.message);
        console.error('Error on Senior Menu', err);
      });
    },
  },
};
</script>
