<template>
  <div>
    <template
      v-if="section"
      >
      <SectionImageWrap
        v-if="section.template_id &&
        (section.template_id.isDualColumn || section.template_id.isWrapTextAround)
        && section.doc_type.isDataDesc && section.detail"
        :pageSection="section" />
      <PageDataDesc
        v-else-if="section.doc_type.isDataDesc"
        :class="[section.template_id ? section.template_id.value : '', section.static_section_id]"
        :pageSection="section.detail" :sectionID="section.static_section_id"/>
      <div v-if="section.doc_type.isDataDescList && section.detail">
        <PageDataDesc
          v-for="(ddSection, index) in section.detail.items"
          :key="index"
          :pageSection="ddSection" />
      </div>
      <template v-if="section.doc_type.isImageAsset">
        <div class="image-area" v-if="section.detail">
          <rz-image-item :image="section.detail.img" />
        </div>
      </template>
      <LinksListGrid
        v-else-if="section.doc_type.isLinksList"
        :title="section.name"
        :linksList="section.detail"
        :isSearchable="section.template_id && (!section.template_id.isSubSection)" />
      <section
        v-else-if="section.doc_type.isGraphqlQuery"
        class="hero single-column guides-code-section"
      >
        <div class="scenarios-wrapper">
          <template v-for="code in section.detail.queries">
            <div class="box" :key="code.key">
              <div class="code-group">
                <template v-if="section.static_section_id === 'add-headers'">
                  <CodeDisplay :code="code" :headerContent="section.name"
                    :responseKey="code.sample_response_key" :notPopup="true" :addHeaders="true"/>
                </template>
                <template v-else>
                  <CodeDisplay :code="code"
                    :responseKey="code.sample_response_key" :notPopup="true"/>
                </template>
              </div>
            </div>
          </template>
        </div>
      </section>
      <section
        v-else-if="section.doc_type.isAPISampleCodeSection"
        :class="['section-sample-code', section.template_id.value]"
        >
        <PageDataDesc
          :class="[section.template_id ? section.template_id.value : '']"
          :pageSection="section.detail" />
        <div class="box" v-if="section.detail.code_groups.length">
          <div class="box-content">
            <SampleCodeSection :codeGroups="section.detail.code_groups"/>
          </div>
        </div>
      </section>
      <template
        v-else-if="section.doc_type.isStaticSection"
        >
        <section class="pull-left page-support-section"
        v-if="section.static_section_id.isSupportSection">
          <SupportSection />
        </section>
        <section v-else>
          <ProductsGroup :groupType="section.static_section_id" />
        </section>
      </template>
    </template>
  </div>
</template>
<style lang="scss">
  section.section-sample-code {
    --subsection-pad-top: #{size(8)};
    --subsection-pad-left: #{size(12)};

    @media screen and (min-width: $breakpoint-md) {
      --subsection-pad-top: #{size(24)};
      --subsection-pad-left: #{size(28)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --subsection-pad-top: #{size(36)};
      --subsection-pad-left: #{size(48)};
    }
    &.sub-section {
      h2 {
        display: none;
      }
    }
    .box {
      padding-left: var(--section-pad-left);
      .loading-msg {
        text-align: left;
      }

      .box-content {
        padding:
        var(--section-box-content-pad-top)
        0
        var(--section-box-content-pad-bottom)
        var(--section-box-content-pad-left);

        background-color: var(--box-color-2);
        color: var(--secondary-text);
      }
    }

    &.sub-section {
      padding-top: var(----subsection-pad-top);
      padding-left: var(--subsection-pad-left);
      section.hero.sub-section {
        padding-left: 0;
        h2 {
          padding-left: var(--section-pad-left);
        }
      }
    }
  }
  section.page-support-section {
    background-color: var(--box-color-3);
  }
  .web-page {
    .page-support-section {
      margin-top: var(--support-section-top-pad);
    }
  }
  .guides-code-section {
    padding-top: size(30);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(40);
    }
  }
</style>
<script>

import PageDataDesc from '@/components/WebPage/PageDataDesc.vue';
import SectionImageWrap from '@/components/WebPage/SectionImageWrap.vue';
import SampleCodeSection from '@/components/API/SampleCodeSection.vue';
import SupportSection from '@/components/SupportSection.vue';
import LinksListGrid from '@/components/WebPage/LinksListGrid.vue';
import ProductsGroup from '@/components/WebPage/Provisional/ProductsGroup.vue';
import CodeDisplay from '@/components/CodeDisplay/GraphqlCodeDisplay.vue';

export default {
  components: {
    SampleCodeSection,
    PageDataDesc,
    SectionImageWrap,
    SupportSection,
    LinksListGrid,
    ProductsGroup,
    CodeDisplay,
  },
  props: {
    section: Object,
  },
};
</script>
